import "./styles.scss";

import React from "react";

import { useApartmentList } from "../../../../../context/apartments-list";

import PdfIcon from "assets/icons/pdf.svg";

const mainClass = "apts-list";

const getFloor = (floor) => {
  switch (floor) {
    case 1:
      return "Pierwsze";
    case 2:
      return "Drugie";
    case 3:
      return "Trzecie";
    case 4:
      return "Czwarte";
    default:
      return "Parter";
  }
};
const getColor = (status) => {
  switch (status) {
    case "1":
      return { background: "#1D9C1B" };
    case "2":
      return { background: "#FFE208" };
    default:
      return { background: "#E60406" };
  }
};

const getStatusName = (status) => {
  switch (status) {
    case "1":
      return "Dostępne";
    case "2":
      return "Zarezerwowane";
    default:
      return "Sprzedane";
  }
};

const ListItem = ({ item, setContactModalOpen }) => {
  const { area, floor, nameBuilding, planLink, rooms, statusId } =
    item?.nieruchomosci;

  const { setAptFromListNumber } = useApartmentList();

  const price = "";

  return (
    <div className={`${mainClass}__row`}>
      <div className={`${mainClass}__item`}>
        <span className={`${mainClass}__item-id`}>
          {nameBuilding.substring(4)}
        </span>
      </div>
      <div className={`${mainClass}__item`}>
        <p className={`${mainClass}__item-size`}>
          <span>{area}</span> m<sup>2</sup>
        </p>
      </div>
      <div className={`${mainClass}__item`}>
        <span className={`${mainClass}__item-rooms`}>{rooms}</span>
      </div>
      <div className={`${mainClass}__item`}>
        <span className={`${mainClass}__item-floor`}>{getFloor(floor)}</span>
      </div>
      <div className={`${mainClass}__item`}>
        <div className={`${mainClass}__item-status`}>
          <span
            style={getColor(statusId)}
            className={`${mainClass}__item-light`}
          ></span>
          <p>{getStatusName(statusId)}</p>
        </div>
      </div>
      <div className={`${mainClass}__item`}>
        <span className={`${mainClass}__item-price`}>
          {price !== "" ? (
            <>
              <span>{price || " - "}</span> zł
            </>
          ) : (
            " - "
          )}
        </span>
      </div>
      <div className={`${mainClass}__item`}>
        <div className={`${mainClass}__item-pdf`}>
          <a
            href={planLink}
            target="_blank"
            rel="noreferrer"
            className={`${mainClass}__item-pdf`}
          >
            <PdfIcon />
          </a>
        </div>
      </div>
      <div className={`${mainClass}__item`}>
        <div className={`${mainClass}__item-more`}>
          <button
            type="button"
            className={`${mainClass}__item-contact`}
            onClick={() => {
              setContactModalOpen(true);
              setAptFromListNumber(`${nameBuilding.substring(4)}`);
            }}
          >
            zapytaj o ofertę
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
