import "./styles.scss";

import React, { useState } from "react";
import MouseTooltip from "react-sticky-mouse-tooltip";

import BuiildingFloors from "./BuiildingFloors/BuiildingFloors";

import PointerIcon from "assets/icons/pointer.svg";
import TooltipPointer from "assets/icons/tooltip_building.svg";

import { useApartmentList } from "../../../context/apartments-list";

const mainClass = "apts-choose-floor";

const ChooseFloor = () => {
  const { formatted_data } = useApartmentList();

  const [tolltipVisible, setTolltipVisible] = useState(false);
  const [hoveredFloor, setHoveredFloor] = useState({
    floor: 0,
    apts_number: 30,
  });

  const onFloorHover = (floorId) => {
    const spread_floor_id = [...floorId];
    const hovered_floor_number = parseInt(spread_floor_id[2]);
    const all_floor_apartments = formatted_data?.filter(
      (item) => item.nieruchomosci.floor?.toString() === spread_floor_id[2]
    );

    const checkAptsAvailibility = () => {
      if (all_floor_apartments) {
        const number_of_available_apartments = all_floor_apartments.filter(
          (apt) => apt?.nieruchomosci?.statusId === "1"
        );
        return number_of_available_apartments.length;
      } else {
        return 0;
      }
    };
    const available_apts_number = checkAptsAvailibility();

    setHoveredFloor({
      floor: hovered_floor_number,
      apts_number: available_apts_number,
    });
    setTolltipVisible(true);
  };

  const onFloorHoverOut = () => {
    setTolltipVisible(false);
  };

  return (
    <section id="choose-floor" className={mainClass}>
      <div className={`${mainClass}__heading`}>
        <h1 className={`${mainClass}__heading--text`}>
          <span>Wybierz</span>
          <br />
          piętro
        </h1>
        <PointerIcon />
      </div>

      <MouseTooltip
        visible={tolltipVisible}
        offsetX={-10}
        offsetY={-75}
        className={`${mainClass}__tooltip`}
      >
        <div className={`${mainClass}__tooltip--wrapper`}>
          <div className={`${mainClass}__tooltip-icon`}>
            <TooltipPointer />
          </div>
          <div className={`${mainClass}__tooltip-content`}>
            <span className={`${mainClass}__tooltip-floor`}>
              P{hoveredFloor?.floor}
            </span>
            <p className={`${mainClass}__tooltip-apts`}>
              <span>{hoveredFloor?.apts_number}</span> apartamentów
            </p>
          </div>
        </div>
      </MouseTooltip>

      <div className={`${mainClass}__image`}>
        <img
          src={
            require("assets/building/FOREST_budynek_kondygnacje.png").default
          }
          alt=""
        />
        <BuiildingFloors
          onFloorHover={onFloorHover}
          onFloorHoverOut={onFloorHoverOut}
        />
      </div>
    </section>
  );
};

export default ChooseFloor;
