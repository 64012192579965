import React from "react";

const Floor4 = ({
  onApartmentHover,
  onApartmentHoverOut,
  onApartmentClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1097.96"
      height="638.88"
      viewBox="0 0 1097.96 638.88"
    >
      <g
        id="D_4_215"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_215")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_215")}
      >
        <g id="D_4_215S">
          <path
            className="cls-2"
            d="m903.85,561.87c0-2.68,2.25-4.88,5-4.88h47.35c2.75,0,5,2.25,5,5v7.41c0,2.75,2.25,5,5,5h8.94c2.75,0,5,2.25,5,5v2.68c0,2.75,2.25,5,5,5h46.69c2.75,0,5,2.25,5,5v1.75c0,2.75-2.25,5-5,5h-1.69c-2.75,0-5,2.25-5,5v6.05c0,2.75-2.25,5-5,5h-111.3c-2.75,0-5-2.25-5-5v-6.05c0-2.75-1.99-5-4.42-5s-4.42-2.25-4.42-5v-22.08c0-2.75,1.99-5,4.42-5s4.42-2.2,4.42-4.88Z"
          />
        </g>
        <g id="D_4_215T">
          <g>
            <path
              className="cls-1"
              d="m915.23,589.95v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
            />
            <path
              className="cls-1"
              d="m926.51,590.05c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m928.61,588.03v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m938.79,590.05c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m940.91,589.95v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m950,589.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m956.74,590.1c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="D_4_214"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_214")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_214")}
      >
        <g id="D_4_214S">
          <path
            className="cls-2"
            d="m904.02,499.61c0-2.75,2.25-5,5-5h47.18c2.75,0,5,2.25,5,5v47.4c0,2.75-2.25,5-5,5h-46.04c-2.75,0-5-2.25-5-5v-.28c0-2.75-2.25-5-5-5h-.42c-2.75,0-5-2.25-5-5v-26.6c0-2.75,2.09-5,4.64-5s4.64-2.25,4.64-5v-.52Z"
          />
        </g>
        <g id="D_4_214T">
          <path
            className="cls-1"
            d="m905.81,527.75v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m917.09,527.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m919.18,525.84v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m929.37,527.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m931.49,527.75v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m940.58,527.75v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m944.14,525.84v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="D_4_213"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_213")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_213")}
      >
        <g id="D_4_213S">
          <path
            className="cls-2"
            d="m1041.82,500.53c0-2.54-2.25-4.62-5-4.62h-47.77c-2.75,0-5,2.25-5,5v46.1c0,2.75,2.25,5,5,5h47.34c2.75,0,5-2.25,5-5v-.28c0-2.75,2.25-5,5-5h.42c2.75,0,5-2.25,5-5v-26.57c0-2.75-2.25-5-4.99-5s-4.99-2.08-4.99-4.62Z"
          />
        </g>
        <g id="D_4_213T">
          <path
            className="cls-1"
            d="m995.73,527.75v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1007.01,527.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1009.11,525.84v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m1019.29,527.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1021.41,527.75v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1030.5,527.75v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1037.1,527.91c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_4_212"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_212")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_212")}
      >
        <g id="D_4_212S">
          <path
            className="cls-2"
            d="m904.02,437.53c0-2.75,2.25-5,5-5h47.18c2.75,0,5,2.25,5,5v46.83c0,2.75-2.25,5-5,5h-46.04c-2.75,0-5-2.25-5-5v-.28c0-2.75-2.25-5-5-5h-.42c-2.75,0-5-2.25-5-5v-26.08c0-2.75,2.09-5,4.64-5s4.64-2.25,4.64-5v-.46Z"
          />
        </g>
        <g id="D_4_212T">
          <path
            className="cls-1"
            d="m906.45,465.1v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m917.73,465.2c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m919.82,463.19v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m930.01,465.2c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m932.13,465.1v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m941.22,465.1v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m944.81,465.1v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="D_4_211"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_211")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_211")}
      >
        <g id="D_4_211S">
          <path
            className="cls-2"
            d="m1041.82,437.78c0-2.63-2.25-4.78-5-4.78h-47.77c-2.75,0-5,2.25-5,5v47.67c0,2.75,2.25,5,5,5h46.56c2.75,0,5-2.25,5-5v-1.31c0-2.75,2.25-5,5-5h1.19c2.75,0,5-2.25,5-5v-26.8c0-2.75-2.25-5-4.99-5s-4.99-2.15-4.99-4.78Z"
          />
        </g>
        <g id="D_4_211T">
          <path
            className="cls-1"
            d="m997.02,465.63v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1008.3,465.74c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1010.4,463.72v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m1020.58,465.74c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1022.7,465.63v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1031.79,465.63v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1036.86,465.63v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="D_4_210"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_210")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_210")}
      >
        <g id="D_4_210S">
          <path
            className="cls-2"
            d="m904.02,375.5c0-2.75,2.25-5,5-5h47.18c2.75,0,5,2.25,5,5v46.83c0,2.75-2.25,5-5,5h-46.04c-2.75,0-5-2.25-5-5v-.28c0-2.75-2.25-5-5-5h-.42c-2.75,0-5-2.25-5-5v-26.07c0-2.75,2.09-5,4.64-5s4.64-2.25,4.64-5v-.47Z"
          />
        </g>
        <g id="D_4_210T">
          <path
            className="cls-1"
            d="m905.87,403.07v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m917.16,403.17c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m919.25,401.16v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m929.44,403.17c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m931.55,403.07v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m940.64,403.07v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m948.19,403.22c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="D_4_209"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_209")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_209")}
      >
        <g id="D_4_209S">
          <path
            className="cls-2"
            d="m1041.82,375.84c0-2.63-2.25-4.78-5-4.78h-47.77c-2.75,0-5,2.25-5,5v46.52c0,2.75,2.25,5,5,5h46.56c2.75,0,5-2.25,5-5v-.29c0-2.75,2.25-5,5-5h1.19c2.75,0,5-2.25,5-5v-26.67c0-2.75-2.25-5-4.99-5s-4.99-2.15-4.99-4.78Z"
          />
        </g>
        <g id="D_4_209T">
          <path
            className="cls-1"
            d="m993.58,403.12v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1004.87,403.22c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1006.96,401.2v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m1017.15,403.22c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1019.27,403.12v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1030.84,403.27c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m1038.91,393.86c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="D_4_208"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_208")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_208")}
      >
        <g id="D_4_208S">
          <path
            className="cls-2"
            d="m904.02,313.03c0-2.75,2.25-5,5-5h47.18c2.75,0,5,2.25,5,5v47.33c0,2.75-2.25,5-5,5h-46.04c-2.75,0-5-2.25-5-5v-.28c0-2.75-2.25-5-5-5h-.42c-2.75,0-5-2.25-5-5v-26.07c0-2.75,2.09-5,4.64-5s4.64-2.25,4.64-5v-.98Z"
          />
        </g>
        <g id="D_4_208T">
          <path
            className="cls-1"
            d="m904.14,341.11v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m915.43,341.21c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m917.52,339.19v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m927.71,341.21c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m929.82,341.11v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m941.39,341.26c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m950.03,341.26c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="D_4_207"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_207")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_207")}
      >
        <g id="D_4_207S">
          <path
            className="cls-2"
            d="m1041.82,314.11c0-2.63-2.25-4.78-5-4.78h-47.77c-2.75,0-5,2.25-5,5v46.52c0,2.75,2.25,5,5,5h47.69c2.75,0,5-2.25,5-5v-.29c0-2.75,2.25-5,5-5h.06c2.75,0,5-2.25,5-5v-26.67c0-2.75-2.25-5-4.99-5s-4.99-2.15-4.99-4.78Z"
          />
        </g>
        <g id="D_4_207T">
          <path
            className="cls-1"
            d="m993.3,339.84v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1004.59,339.95c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1006.68,337.93v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m1016.87,339.95c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1018.99,339.84v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1030.56,340c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m1036.9,339.84l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
        </g>
      </g>
      <g
        id="D_4_206"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_206")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_206")}
      >
        <g id="D_4_206S">
          <path
            className="cls-2"
            d="m904.02,251.21c0-2.75,2.25-5.03,5-5.07l9.32-.13c2.75-.04,5-2.32,5-5.07v-11.53c0-2.75,2.25-5,5-5h27.86c2.75,0,5,2.25,5,5v68.4c0,2.75-2.25,5-5,5h-47.18c-2.75,0-5-2.25-5-5v-46.61Z"
          />
        </g>
        <g id="D_4_206T">
          <path
            className="cls-1"
            d="m908.28,276.33v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m919.56,276.43c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m921.65,274.42v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m931.84,276.43c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m933.96,276.33v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m945.53,276.48c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m954.47,276.48c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="D_4_205"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_205")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_205")}
      >
        <g id="D_4_205S">
          <path
            className="cls-2"
            d="m1046.81,230.65c2.75,0,4.99-2.25,4.99-5v-26.63c0-2.75-2.25-5-5-5h-1.28c-2.75,0-5-2.25-5-5v-.26c0-2.75-2.25-5-5-5h-46.48c-2.75,0-5,2.25-5,5v110.34c0,2.75,2.25,5,5,5h47.69c2.75,0,5-2.25,5-5v-.91c0-2.75,2.25-5,5-5h.06c2.75,0,5-2.25,5-5v-26.2c0-2.75-2.25-5-4.99-5s-4.99-2.25-4.99-5v-16.34c0-2.75,2.25-5,4.99-5Z"
          />
        </g>
        <g id="D_4_205T">
          <path
            className="cls-1"
            d="m989.42,247.78v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1000.7,247.89c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1002.79,245.87v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m1012.98,247.89c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1015.1,247.78v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1026.67,247.94c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m1034.64,247.94c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_4_204"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_4_204")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_4_204")}
      >
        <g id="D_4_204S">
          <path
            className="cls-2"
            d="m957.21,105.24c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-57.42c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.1-5,4.68-5s4.68-2.18,4.68-4.86,2.25-4.86,5-4.86l26.65-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h11.42Z"
          />
        </g>
        <g id="D_4_204T">
          <g>
            <path
              className="cls-1"
              d="m903.83,135.99v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
            />
            <path
              className="cls-1"
              d="m915.11,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m917.21,134.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m927.39,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m929.51,135.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m941.08,136.15c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
            <path
              className="cls-1"
              d="m945.87,134.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_4_203"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_203")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_203")}
      >
        <g id="C_4_203S">
          <path
            className="cls-2"
            d="m882.91,242.53c1.95,0,3.54-2.25,3.54-5v-47.45c0-2.75-2.25-5-5-5h-43.44c-2.75,0-5,2.25-5,5v47.73c0,2.75,2.14,5,4.76,5s4.79,2.08,4.83,4.62,2.32,4.62,5.07,4.62h26.69c2.75,0,5-2.14,5-4.76s1.59-4.76,3.54-4.76Z"
          />
        </g>
        <g id="C_4_203T">
          <path
            className="cls-1"
            d="m840.61,219.91c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m846.37,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m848.46,217.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m858.65,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m860.76,219.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m872.34,219.91c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m880.16,219.91c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_4_202"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_202")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_202")}
      >
        <g id="C_4_202S">
          <path
            className="cls-2"
            d="m882.48,105.24c2.48,0,4.51,2.25,4.51,5v47.6c0,2.75-2.25,5-5,5h-43.67c-2.75,0-5-2.25-5-5v-47.6c0-2.75,1.81-5,4.03-5s4.03-2.18,4.03-4.86,2.25-4.86,5-4.86l26.6-.03c2.75,0,5,2.19,5,4.87s2.03,4.88,4.51,4.88Z"
          />
        </g>
        <g id="C_4_202T">
          <g>
            <path
              className="cls-1"
              d="m842.39,136.15c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m848.15,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m850.24,134.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m860.43,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m862.55,135.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m874.12,136.15c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
            <path
              className="cls-1"
              d="m878.93,135.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_4_201"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_201")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_201")}
      >
        <g id="C_4_201S">
          <path
            className="cls-2"
            d="m823.26,105.24c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-46.96c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.13-5,4.73-5s4.73-2.18,4.73-4.86,2.25-4.86,5-4.86l27.2-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h.31Z"
          />
        </g>
        <g id="C_4_201T">
          <g>
            <path
              className="cls-1"
              d="m781.64,136.15c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m787.4,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m789.49,134.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m799.68,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m801.8,135.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m813.37,136.15c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
            <path
              className="cls-1"
              d="m819.66,135.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_4_200"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_200")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_200")}
      >
        <g id="C_4_200S">
          <path
            className="cls-2"
            d="m822.92,242.53c2.75,0,5-2.25,5-5v-47.45c0-2.75-2.25-5-5-5h-46.88c-2.75,0-5,2.25-5,5v47.73c0,2.75,2.14,5,4.76,5s4.79,2.08,4.83,4.62,2.32,4.62,5.07,4.62h26.69c2.75,0,5-2.14,5-4.76s2.25-4.76,5-4.76h.52Z"
          />
        </g>
        <g id="C_4_200T">
          <path
            className="cls-1"
            d="m779.8,219.91c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m785.56,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m787.65,217.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m797.84,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m799.95,219.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m811.53,219.91c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m820.29,219.91c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="C_4_199"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_199")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_199")}
      >
        <g id="C_4_199S">
          <path
            className="cls-2"
            d="m760.34,242.53c2.75,0,5-2.25,5-5v-47.45c0-2.75-2.25-5-5-5h-46.32c-2.75,0-5,2.25-5,5v47.73c0,2.75,2.14,5,4.76,5s4.79,2.08,4.83,4.62,2.32,4.62,5.07,4.62h26.16c2.75,0,5-2.14,5-4.76s2.25-4.76,5-4.76h.5Z"
          />
        </g>
        <g id="C_4_199T">
          <path
            className="cls-1"
            d="m719.86,219.91c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m725.62,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m727.71,217.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m737.9,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m741.49,219.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m748.33,210.5c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m756.56,210.5c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="C_4_198"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_198")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_198")}
      >
        <g id="C_4_198S">
          <path
            className="cls-2"
            d="m760.84,105.24c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-46.96c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.13-5,4.73-5s4.73-2.18,4.73-4.86,2.25-4.86,5-4.86l27.2-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h.31Z"
          />
        </g>
        <g id="C_4_198T">
          <g>
            <path
              className="cls-1"
              d="m719.04,136.15c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m724.8,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m726.89,134.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m737.08,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m740.67,135.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m747.51,126.74c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
            <path
              className="cls-1"
              d="m756.3,136.15c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_4_197"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_197")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_197")}
      >
        <g id="C_4_197S">
          <path
            className="cls-2"
            d="m698.97,242.57c2.75,0,5-2.25,5-5v-47.49c0-2.75-2.25-5-5-5h-47.4c-2.75,0-5,2.25-5,5v46.8c0,2.75,2.25,5,5,5h.68c2.75,0,5.03,2.25,5.07,5v.17c.04,2.75,2.32,5,5.07,5h25.23c2.75,0,5-2.13,5-4.74s2.25-4.74,5-4.74h1.35Z"
          />
        </g>
        <g id="C_4_197T">
          <path
            className="cls-1"
            d="m657.52,219.91c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m663.28,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m665.37,217.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m675.56,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m679.15,219.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m685.99,210.5c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m692.49,219.76l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
        </g>
      </g>
      <g
        id="C_4_196"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_196")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_196")}
      >
        <g id="C_4_196S">
          <path
            className="cls-2"
            d="m698.54,105.24c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-46.96c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.13-5,4.73-5s4.73-2.18,4.73-4.86,2.25-4.86,5-4.86l27.2-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h.31Z"
          />
        </g>
        <g id="C_4_196T">
          <g>
            <path
              className="cls-1"
              d="m656.89,136.15c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m662.64,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m664.74,134.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m674.93,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m678.52,135.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m685.36,126.74c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
            <path
              className="cls-1"
              d="m694.45,136.15c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_4_195"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_195")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_195")}
      >
        <g id="C_4_195S">
          <path
            className="cls-2"
            d="m636.31,242.57c2.75,0,5-2.25,5-5v-47.49c0-2.75-2.25-5-5-5h-47.4c-2.75,0-5,2.25-5,5v46.8c0,2.75,2.25,5,5,5h.68c2.75,0,5.03,2.25,5.07,5v.17c.04,2.75,2.32,5,5.07,5h26.38c2.75,0,5-2.13,5-4.74s2.25-4.74,5-4.74h.2Z"
          />
        </g>
        <g id="C_4_195T">
          <path
            className="cls-1"
            d="m595.01,219.91c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m600.77,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m602.87,217.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m613.05,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m616.64,219.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m623.49,210.5c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m631.61,219.91c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_4_194"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_194")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_194")}
      >
        <g id="C_4_194S">
          <path
            className="cls-2"
            d="m636.4,105.24c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-47.01c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.13-5,4.73-5s4.73-2.18,4.73-4.86,2.25-4.86,5-4.86l26.41-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h1.14Z"
          />
        </g>
        <g id="C_4_194T">
          <g>
            <path
              className="cls-1"
              d="m594.37,136.15c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m600.13,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m602.22,134.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m612.41,136.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m616,135.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m622.84,126.74c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
            <path
              className="cls-1"
              d="m627.79,134.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_4_193"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_193")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_193")}
      >
        <g id="C_4_193S">
          <path
            className="cls-2"
            d="m573.77,242.57c2.75,0,5-2.25,5-5v-47.49c0-2.75-2.25-5-5-5h-47.4c-2.75,0-5,2.25-5,5v46.8c0,2.75,2.25,5,5,5h.68c2.75,0,5.03,2.25,5.07,5v.17c.04,2.75,2.32,5,5.07,5h26.34c2.75,0,5-2.13,5-4.74s2.25-4.74,5-4.74h.24Z"
          />
        </g>
        <g id="C_4_193T">
          <g>
            <path
              className="cls-1"
              d="m532.49,219.91c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m538.25,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m540.34,217.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m550.53,219.86c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m554.12,219.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m560.96,210.5c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
            <path
              className="cls-1"
              d="m568.94,219.91c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_4_192"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_4_192")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_4_192")}
      >
        <g id="C_4_192S">
          <path
            className="cls-2"
            d="m574.16,105.24c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-32.79c-2.75,0-5-2.25-5-5v-18.57c0-2.75-2.25-4.99-5-4.98h-2.92c-2.75.03-5-2.21-5-4.96v-19.09c0-2.75,1.83-5,4.08-5s4.08-2.18,4.08-4.86,2.25-4.86,5-4.86l26.41-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h1.14Z"
          />
        </g>
        <g id="C_4_192T">
          <g>
            <path
              className="cls-1"
              d="m534.66,124.18c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m540.42,124.13c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m542.52,122.11v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m552.71,124.13c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m556.29,124.02v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m563.14,114.77c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
            <path
              className="cls-1"
              d="m568.11,124.02v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_4_191"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_191")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_191")}
      >
        <g id="B_4_191S">
          <path
            className="cls-2"
            d="m495.57,44.11c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-47.01c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.13-5,4.73-5s4.73-2.18,4.73-4.86,2.25-4.86,5-4.86l26.41-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h1.14Z"
          />
        </g>
        <g id="B_4_191T">
          <g>
            <path
              className="cls-1"
              d="m451.25,74.43v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m461.75,74.53c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m463.84,72.51v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m474.03,74.53c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m477.62,74.43v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m484.46,65.17c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
            <path
              className="cls-1"
              d="m490.91,74.43v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_4_190"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_190")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_190")}
      >
        <g id="B_4_190S">
          <path
            className="cls-2"
            d="m456.81,184.21c2.75,0,5-2.25,5-5v-22.32c0-2.75-2.25-5-5-5h-14.58c-2.75,0-5-2.25-5-5v-18.03c0-2.75-2.25-5-5-5h-45.97c-2.75,0-5,2.25-5,5v47.6c0,2.75,2.18,5,4.85,5s4.85,2.18,4.85,4.86,2.25,4.86,5,4.86l26.46.03c2.75,0,5-1.57,5-3.49s2.25-3.5,5-3.5h24.39Z"
          />
        </g>
        <g id="B_4_190T">
          <path
            className="cls-1"
            d="m386.59,161.33v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m397.08,161.43c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m399.18,159.42v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m409.36,161.43c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m412.95,161.33v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m419.8,152.07c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m428.72,161.48c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="B_4_189"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_189")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_189")}
      >
        <g id="B_4_189S">
          <path
            className="cls-2"
            d="m433.09,44.11c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-47.01c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.13-5,4.73-5s4.73-2.18,4.73-4.86,2.25-4.86,5-4.86l26.41-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h1.14Z"
          />
        </g>
        <g id="B_4_189T">
          <g>
            <path
              className="cls-1"
              d="m386.71,74.43v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m397.21,74.53c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m399.3,72.51v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m409.49,74.53c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m413.08,74.43v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m420.49,74.58c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
            <path
              className="cls-1"
              d="m428.44,65.17c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_4_188"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_188")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_188")}
      >
        <g id="B_4_188S">
          <path
            className="cls-2"
            d="m370.72,181.15c2.74.17,4.99-1.94,4.99-4.69v-47.6c0-2.75-2.25-5-5-5h-47.4c-2.75,0-5,2.25-5,5v47.6c0,2.75,2.25,5,5,5h.32c2.75,0,5,2.18,5,4.86s2.25,4.82,5,4.77l26.62-.44c2.75-.05,5-2.28,5-4.96s2.25-4.74,4.99-4.57l.48.03Z"
          />
        </g>
        <g id="B_4_188T">
          <path
            className="cls-1"
            d="m325.03,161.33v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m335.53,161.43c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m337.62,159.42v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m347.81,161.43c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m351.4,161.33v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m358.81,161.48c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m367.34,161.48c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="B_4_187"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_187")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_187")}
      >
        <g id="B_4_187S">
          <path
            className="cls-2"
            d="m370.53,44.11c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-46.36c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.13-5,4.73-5s4.73-2.18,4.73-4.86,2.25-4.86,5-4.86l26.41-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h.49Z"
          />
        </g>
        <g id="B_4_187T">
          <g>
            <path
              className="cls-1"
              d="m324.92,74.43v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m335.42,74.53c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m337.51,72.51v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m347.7,74.53c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m351.29,74.43v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m358.7,74.58c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
            <path
              className="cls-1"
              d="m364.92,74.43l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_4_186"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_186")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_186")}
      >
        <g id="B_4_186S">
          <path
            className="cls-2"
            d="m309.06,181.45c1.98,0,3.61-2.25,3.61-5v-47.6c0-2.75-2.25-5-5-5h-45.97c-2.75,0-5,2.25-5,5v47.6c0,2.75,2.18,5,4.85,5s4.85,2.18,4.85,4.86,2.25,4.86,5,4.86l29.06.03c2.75,0,5-2.19,5-4.87s1.62-4.88,3.61-4.88Z"
          />
        </g>
        <g id="B_4_186T">
          <path
            className="cls-1"
            d="m262.14,161.33v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m272.64,161.43c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m274.73,159.42v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m284.92,161.43c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m288.51,161.33v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m295.92,161.48c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m304.74,161.48c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="B_4_185"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_185")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_185")}
      >
        <g id="B_4_185S">
          <path
            className="cls-2"
            d="m308.97,44.11c2.75,0,5,2.25,5,5v47.6c0,2.75-2.25,5-5,5h-47.27c-2.75,0-5-2.25-5-5v-47.6c0-2.75,1.75-5,3.89-5s3.89-2.18,3.89-4.86,2.25-4.86,5-4.86l28.45-.03c2.75,0,5,2.19,5,4.87s2.25,4.88,5,4.88h1.03Z"
          />
        </g>
        <g id="B_4_185T">
          <g>
            <path
              className="cls-1"
              d="m263.06,74.43v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m273.56,74.53c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m275.65,72.51v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m285.84,74.53c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m289.43,74.43v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m296.84,74.58c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
            <path
              className="cls-1"
              d="m304.69,74.58c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_4_184"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_184")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_184")}
      >
        <g id="B_4_184S">
          <path
            className="cls-2"
            d="m246.05,181.45c2.34,0,4.26-2.25,4.26-5v-47.6c0-2.75-2.25-5-5-5h-42.86c-2.75,0-5,2.25-5,5v47.6c0,2.75,1.75,5,3.89,5s3.89,2.18,3.89,4.86,2.25,4.86,5,4.86l26.55.03c2.75,0,5-2.19,5-4.87s1.92-4.88,4.26-4.88Z"
          />
        </g>
        <g id="B_4_184T">
          <g>
            <path
              className="cls-1"
              d="m200.85,161.78v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m211.35,161.89c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m213.44,159.87v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m223.63,161.89c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m227.22,161.78v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m234.63,161.94c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
            <path
              className="cls-1"
              d="m239.3,159.87v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_4_183"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_4_183")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_4_183")}
      >
        <g id="B_4_183S">
          <path
            className="cls-2"
            d="m246.68,44.11c2.34,0,4.26,2.25,4.26,5v47.6c0,2.75-2.25,5-5,5h-42.86c-2.75,0-5-2.25-5-5v-47.6c0-2.75,1.75-5,3.89-5s3.89-2.18,3.89-4.86,2.25-4.86,5-4.86l26.55-.03c2.75,0,5,2.19,5,4.87s1.92,4.88,4.26,4.88Z"
          />
        </g>
        <g id="B_4_183T">
          <g>
            <path
              className="cls-1"
              d="m202.11,74.88v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m212.61,74.98c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m214.7,72.97v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m224.89,74.98c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m228.48,74.88v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m235.89,75.03c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
            <path
              className="cls-1"
              d="m243.6,75.03c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_4_182"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_182")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_182")}
      >
        <g id="A_4_182S">
          <path
            className="cls-2"
            d="m187.77,44.11c2.58,0,4.69,2.25,4.69,5v47.6c0,2.75-2.25,5-5,5h-70.26c-2.75,0-5-2.25-5-5v-47.6c0-2.75,2.25-5,5-5h22.84c2.75,0,5-2.18,5-4.86s2.25-4.86,5-4.86h27.64c2.75,0,5.09,2.18,5.21,4.86s2.31,4.86,4.89,4.86Z"
          />
        </g>
        <g id="A_4_182T">
          <path
            className="cls-1"
            d="m128.89,76.75l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m140.59,76.85c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m142.68,74.84v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m152.87,76.85c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m156.46,76.75v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m163.87,76.9c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m168.57,76.75v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="A_4_181"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_181")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_181")}
      >
        <g id="A_4_181S">
          <path
            className="cls-2"
            d="m95.51,107.15c2.75.02,5,2.28,5,5.03v73c0,2.75-2.25,5.02-5,5.05l-47.57.52c-2.75.03-5-1.81-5-4.1s.35-6.37.77-9.09l1.97-12.65c.42-2.72-1.1-5.28-3.39-5.69s-3.71-2.96-3.17-5.66l5.28-26.42c.54-2.7,2.9-4.54,5.25-4.1s4.7-1.41,5.21-4.11l1.37-7.13c.52-2.7,3.19-4.9,5.94-4.88l33.34.21Z"
          />
        </g>
        <g id="A_4_181T">
          <g>
            <path
              className="cls-1"
              d="m47.25,147.97l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m58.95,148.08c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m61.04,146.06v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m71.23,148.08c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m74.82,147.97v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m82.23,148.13c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
            <path
              className="cls-1"
              d="m88.41,147.97v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_4_180"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_180")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_180")}
      >
        <g id="A_4_180S">
          <path
            className="cls-2"
            d="m100.94,247.35c0,2.75-2.25,5-5,5h-47.97c-2.75,0-5-2.25-5-5v-.57c0-2.75-2.25-5-5-5h-.51c-2.75,0-5-2.25-5-5v-26.65c0-2.75,2.25-5,5-5h.51c2.75,0,5-2.03,5-4.51s2.25-4.51,5-4.51h47.97c2.75,0,5,2.25,5,5v46.23Z"
          />
        </g>
        <g id="A_4_180T">
          <g>
            <path
              className="cls-1"
              d="m42.68,228.03l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m54.38,228.13c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m56.47,226.12v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m66.66,228.13c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m70.25,228.03v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m77.66,228.18c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
            <path
              className="cls-1"
              d="m86.32,228.18c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_4_179"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_179")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_179")}
      >
        <g id="A_4_179S">
          <path
            className="cls-2"
            d="m128.32,314.89c-2.75,0-5-2.25-5.01-5l-.1-73.14c0-2.75,2.24-5,4.99-5h19.66c2.75,0,5-2.25,5-5v-25.73c0-2.75,2.25-5,5-5h16.15c2.75,0,5,2.25,5,5v61.2c0,2.75,2.25,5,5,5h1.25c2.75,0,5,2.25,5,5v26.97c0,2.75-2.25,4.97-5,4.93h-.22c-2.75-.04-5,2.18-5,4.93v.85c0,2.75-2.25,5-5,5h-46.74Z"
          />
        </g>
        <g id="A_4_179T">
          <path
            className="cls-1"
            d="m133.31,287.34l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m145.01,287.44c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m147.1,285.43v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m157.29,287.44c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m160.88,287.34v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m165.99,287.34l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
          <path
            className="cls-1"
            d="m175.73,278.08c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="A_4_178"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_178")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_178")}
      >
        <g id="A_4_178S">
          <path
            className="cls-2"
            d="m100.94,308.79c0,2.75-2.25,5-5,5h-47.97c-2.75,0-5-2.25-5-5v-.57c0-2.75-2.25-5-5-5h-.51c-2.75,0-5-2.25-5-5v-26.65c0-2.75,2.25-5,5-5h.51c2.75,0,5-2.03,5-4.51s2.25-4.51,5-4.51h47.97c2.75,0,5,2.25,5,5v46.23Z"
          />
        </g>
        <g id="A_4_178T">
          <g>
            <path
              className="cls-1"
              d="m43.06,289.47l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m54.76,289.57c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m56.86,287.56v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m67.04,289.57c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m70.63,289.47v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m75.75,289.47l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
            <path
              className="cls-1"
              d="m86.05,289.62c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_4_177"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_177")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_177")}
      >
        <g id="A_4_177S">
          <path
            className="cls-2"
            d="m123.32,371.02c0,2.75,2.25,5,5,5h47.97c2.75,0,5-2.04,5-4.54s2.25-4.54,5-4.54h.51c2.75,0,5-2.25,5-5v-27.95c0-2.75-2.25-5-5-5h-.51c-2.75,0-5-2.03-5-4.51s-2.25-4.51-5-4.51h-47.97c-2.75,0-5,2.25-5,5v46.05Z"
          />
        </g>
        <g id="A_4_177T">
          <g>
            <path
              className="cls-1"
              d="m134.19,351.88l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m145.89,351.99c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m147.98,349.97v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m158.17,351.99c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m161.76,351.88v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m166.87,351.88l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
            <path
              className="cls-1"
              d="m174.88,351.88l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_4_176"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_176")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_176")}
      >
        <g id="A_4_176S">
          <path
            className="cls-2"
            d="m100.94,371.13c0,2.75-2.25,5-5,5h-47.97c-2.75,0-5-2.25-5-5v-.57c0-2.75-2.25-5-5-5h-.51c-2.75,0-5-2.25-5-5v-26.65c0-2.75,2.25-5,5-5h.51c2.75,0,5-2.03,5-4.51s2.25-4.51,5-4.51h47.97c2.75,0,5,2.25,5,5v46.23Z"
          />
        </g>
        <g id="A_4_176T">
          <path
            className="cls-1"
            d="m43.21,351.8l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m54.91,351.91c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m57,349.89v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m67.19,351.91c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m70.78,351.8v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m75.9,351.8l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
          <path
            className="cls-1"
            d="m86.5,351.96c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="A_4_175"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_175")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_175")}
      >
        <g id="A_4_175S">
          <path
            className="cls-2"
            d="m123.32,433.68c0,2.75,2.25,5,5,5h47.97c2.75,0,5-2.25,5-5v-1.69c0-2.75,2.25-5,5-5h.51c2.75,0,5-2.25,5-5v-26.65c0-2.75-2.25-5-5-5h-.51c-2.75,0-5-2.03-5-4.51s-2.25-4.51-5-4.51h-47.97c-2.75,0-5,2.25-5,5v47.35Z"
          />
        </g>
        <g id="A_4_175T">
          <g>
            <path
              className="cls-1"
              d="m134.35,413.24l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m146.05,413.35c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m148.14,411.33v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m158.33,413.35c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m161.92,413.24v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m167.04,413.24l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
            <path
              className="cls-1"
              d="m176.67,413.4c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_4_174"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_174")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_174")}
      >
        <g id="A_4_174S">
          <path
            className="cls-2"
            d="m100.94,433.68c0,2.75-2.25,5-5,5h-47.97c-2.75,0-5-2.25-5-5v-1.69c0-2.75-2.25-5-5-5h-.51c-2.75,0-5-2.25-5-5v-26.65c0-2.75,2.25-5,5-5h.51c2.75,0,5-2.03,5-4.51s2.25-4.51,5-4.51h47.97c2.75,0,5,2.25,5,5v47.35Z"
          />
        </g>
        <g id="A_4_174T">
          <g>
            <path
              className="cls-1"
              d="m42.88,413.24l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m54.58,413.35c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m56.67,411.33v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m66.86,413.35c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m70.45,413.24v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m75.56,413.24l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
            <path
              className="cls-1"
              d="m82.02,411.33v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_4_173"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_4_173")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_4_173")}
      >
        <g id="A_4_173S">
          <path
            className="cls-2"
            d="m48.06,501.86c-2.75,0-5.01-2.25-5.03-5l-.04-6.38c-.02-2.75-2.28-5-5.03-5h-.51c-2.75,0-5-2.25-5-5v-21.9c0-2.75,2.25-5,5-5h.51c2.75,0,4.99-2.18,4.99-4.85s2.24-4.85,4.99-4.85h47.98c2.75,0,5,2.25,4.99,5l-.02,8.01c0,2.75,1.18,5,2.63,5s2.64-2.25,2.64-5v-24.19c0-2.75,2.25-5,5-5h1.82c2.75,0,5,2.25,5,5v24.19c0,2.75,1.16,5,2.58,5s2.58-2.25,2.58-5v-7.99c0-2.75,2.25-5,5-5h46.9c2.75,0,5,2.17,5,4.82s2.25,4.82,5,4.82h.22c2.75,0,5,2.25,5,5v21.45c0,2.75-2.25,5-5,5h-.27c-2.75,0-4.99,2.25-4.98,5l.02,6.88c0,2.75-2.23,5-4.98,5H48.06Z"
          />
        </g>
        <g id="A_4_173T">
          <path
            className="cls-1"
            d="m89.36,483.57l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m101.06,483.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m103.15,481.66v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m113.34,483.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m116.93,483.57v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m122.04,483.57l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
          <path
            className="cls-1"
            d="m131.53,483.72c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Floor4;
