import React from "react";

const Floor1 = ({
  onApartmentHover,
  onApartmentHoverOut,
  onApartmentClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1086.24"
      height="639.79"
      viewBox="0 0 1086.24 639.79"
    >
      <g
        id="D_1_82"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_82")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_82")}
      >
        <g id="D_1_82S">
          <path
            className="cls-2"
            d="m895,563.29c0-2.75,2.25-5,5-5h58.8c2.75,0,5,2.25,5,5v7.35c0,2.75,2.25,5,5,5h6.34c2.75,0,5,2.25,5,5v1.4c0,2.75,2.25,5,5,5h46.69c2.75,0,5,2.25,5,5v19.16c0,2.75-2.25,5-5,5h-131.84c-2.75,0-5-2.25-5-5v-47.9Z"
          />
        </g>
        <g id="D_1_82T">
          <path
            className="cls-1"
            d="m915.99,592.73v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m927.28,592.83c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m930.87,592.73v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m935.72,592.83c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m941.66,592.88c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m946.36,592.73v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="D_1_81"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_81")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_81")}
      >
        <g id="D_1_81S">
          <path
            className="cls-2"
            d="m895,499.4c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v48.91c0,2.75-2.25,5-5,4.99l-57.5-.07c-2.75,0-5-2.26-5-5.01v-48.83Z"
          />
        </g>
        <g id="D_1_81T">
          <path
            className="cls-1"
            d="m911.85,527.65v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m923.13,527.75c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m926.72,527.65v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m931.57,527.75c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m937.51,527.81c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m943.69,527.65v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="D_1_80"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_80")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_80")}
      >
        <g id="D_1_80S">
          <path
            className="cls-2"
            d="m985.34,500.91c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_1_80T">
          <path
            className="cls-1"
            d="m999.77,528.22v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1011.06,528.33c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1014.65,528.22v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1019.5,528.33c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1025.44,528.38c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m1034.1,528.38c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="D_1_79"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_79")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_79")}
      >
        <g id="D_1_79S">
          <path
            className="cls-2"
            d="m895,438.74c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v45.66c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-45.58Z"
          />
        </g>
        <g id="D_1_79T">
          <path
            className="cls-1"
            d="m910.52,465.37v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m921.81,465.47c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m925.4,465.37v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m930.25,465.47c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m933.89,465.37l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
          <path
            className="cls-1"
            d="m943.63,456.11c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="D_1_78"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_78")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_78")}
      >
        <g id="D_1_78S">
          <path
            className="cls-2"
            d="m985.34,438.74c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_1_78T">
          <path
            className="cls-1"
            d="m1000.15,466.06v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1011.44,466.17c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1015.03,466.06v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1019.88,466.17c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1023.53,466.06l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
          <path
            className="cls-1"
            d="m1033.83,466.22c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="D_1_77"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_77")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_77")}
      >
        <g id="D_1_77S">
          <path
            className="cls-2"
            d="m895,375.35c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v48.35c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-48.27Z"
          />
        </g>
        <g id="D_1_77T">
          <path
            className="cls-1"
            d="m910.63,403.32v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m921.92,403.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m925.51,403.32v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m930.36,403.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m934,403.32l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
          <path
            className="cls-1"
            d="m942.01,403.32l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
        </g>
      </g>
      <g
        id="D_1_76"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_76")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_76")}
      >
        <g id="D_1_76S">
          <path
            className="cls-2"
            d="m985.34,376.6c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_1_76T">
          <path
            className="cls-1"
            d="m1000.3,403.91v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1011.59,404.02c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1015.18,403.91v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1020.03,404.02c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1023.67,403.91l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
          <path
            className="cls-1"
            d="m1034.28,404.07c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="D_1_75"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_75")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_75")}
      >
        <g id="D_1_75S">
          <path
            className="cls-2"
            d="m895,313.03c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v47.24c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-47.17Z"
          />
        </g>
        <g id="D_1_75T">
          <path
            className="cls-1"
            d="m910.79,340.45v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m922.08,340.55c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m925.67,340.45v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m930.52,340.55c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m934.16,340.45l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
          <path
            className="cls-1"
            d="m943.8,340.6c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_1_74"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_74")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_74")}
      >
        <g id="D_1_74S">
          <rect
            className="cls-2"
            x="985.35"
            y="309.33"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_1_74T">
          <path
            className="cls-1"
            d="m1000.01,341.99v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1011.29,342.09c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1014.88,341.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1019.74,342.09c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1023.38,341.99l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
          <path
            className="cls-1"
            d="m1029.83,340.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="D_1_73"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_73")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_73")}
      >
        <g id="D_1_73S">
          <path
            className="cls-2"
            d="m923.35,229.42c0-2.75,2.25-5,5-5h29.16c2.75,0,5,2.25,5,5v68.4c0,2.75-2.25,5-5,5h-57.5c-2.75,0-4.99-2.25-4.98-5l.14-40.82c0-2.75-2.23-5-4.98-4.99l-23.76.03c-2.75,0-5-2.24-5-4.99v-56.97c0-2.75,2.25-5,5-5h18.17c2.75,0,5.03,2.25,5.08,5l.77,50.88c.04,2.75,2.33,5,5.08,5h22.84c2.75,0,5-2.25,5-5v-11.53Z"
          />
        </g>
        <g id="D_1_73T">
          <path
            className="cls-1"
            d="m909.73,278v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m921.02,278.11c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m924.61,278v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m929.46,278.11c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m933.1,278l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
          <path
            className="cls-1"
            d="m942.59,278.16c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_1_72"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_72")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_72")}
      >
        <g id="D_1_72S">
          <rect
            className="cls-2"
            x="985.35"
            y="246.88"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_1_72T">
          <path
            className="cls-1"
            d="m1000.65,279.54v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1011.93,279.64c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1015.52,279.54v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1020.38,279.64c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1024.02,279.54l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
          <path
            className="cls-1"
            d="m1030.5,279.54v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="D_1_71"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_71")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_71")}
      >
        <g id="D_1_71S">
          <rect
            className="cls-2"
            x="985.35"
            y="184.02"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_1_71T">
          <path
            className="cls-1"
            d="m1001.92,216.68v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1013.21,216.78c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1016.8,216.68v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1021.65,216.78c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1025.3,216.68l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
          <path
            className="cls-1"
            d="m1033.25,216.68v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="D_1_70"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_70")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_70")}
      >
        <g id="D_1_70S">
          <rect
            className="cls-2"
            x="956.87"
            y="95.68"
            width="51.47"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_1_70T">
          <path
            className="cls-1"
            d="m962.88,132.95v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m974.17,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m977.76,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m982.61,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m986.25,132.95l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
          <path
            className="cls-1"
            d="m996.69,133.11c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="D_1_69"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_1_69")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_1_69")}
      >
        <g id="D_1_69S">
          <path
            className="cls-2"
            d="m951.66,156.13c0,3.58-2.93,6.5-6.5,6.5h-43.78c-3.58,0-6.48-2.92-6.46-6.5l.29-53.95c.02-3.57,2.96-6.5,6.54-6.5h43.41c3.57,0,6.5,2.92,6.5,6.5v53.95Z"
          />
        </g>
        <g id="D_1_69T">
          <path
            className="cls-1"
            d="m905.21,132.95v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m916.49,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m920.08,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m924.94,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m931.17,133.11c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m938.53,123.7c.83,0,1.55.17,2.16.52.6.35,1.07.85,1.39,1.51.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66c-.69.38-1.49.57-2.4.57-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36s.58.1.89.1c.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85-.27.23-.58.4-.93.52-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61.31-.45.72-.8,1.24-1.05.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16-.22.11-.4.26-.53.47s-.19.44-.19.72c0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7c-.12-.2-.3-.36-.54-.48-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="C_1_68"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_68")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_68")}
      >
        <g id="C_1_68S">
          <rect
            className="cls-2"
            x="833.51"
            y="95.68"
            width="53.92"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_68T">
          <path
            className="cls-1"
            d="m847.86,133.11c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m853.62,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m857.21,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m862.07,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m868.3,133.11c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m876.23,133.11c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98c-.32-.42-.48-.91-.48-1.48s.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9s1.24-.31,2-.31,1.45.1,2.02.31,1.02.5,1.33.9.47.87.47,1.44-.16,1.06-.48,1.48c-.32.42-.76.75-1.34.98-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38.29-.39.7-.68,1.23-.9.53-.21,1.13-.32,1.81-.32s1.31.11,1.83.32c.52.21.93.51,1.23.9.3.39.45.85.45,1.38s-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.84-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3-.39.48-.39.84.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="C_1_67"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_67")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_67")}
      >
        <g id="C_1_67S">
          <rect
            className="cls-2"
            x="771.04"
            y="95.68"
            width="57.07"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_67T">
          <g>
            <path
              className="cls-1"
              d="m785.68,133.11c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m791.44,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m795.03,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m799.89,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m806.12,133.11c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
            />
            <path
              className="cls-1"
              d="m811.75,132.95l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_1_66"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_66")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_66")}
      >
        <g id="C_1_66S">
          <rect
            className="cls-2"
            x="772.34"
            y="185.08"
            width="83.87"
            height="66.97"
            rx="5"
            ry="5"
          />
        </g>
        <g id="C_1_66T">
          <path
            className="cls-1"
            d="m800.86,222.51c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m806.62,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m810.21,222.36v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m815.07,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m821.3,222.51c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m829.53,222.51c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="C_1_65"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_65")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_65")}
      >
        <g id="C_1_65S">
          <rect
            className="cls-2"
            x="708.76"
            y="185.08"
            width="57.07"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_65T">
          <path
            className="cls-1"
            d="m724.15,222.51c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m729.91,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m733.5,222.36v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m738.36,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m744.6,222.51c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m751.85,222.51c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_1_64"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_64")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_64")}
      >
        <g id="C_1_64S">
          <rect
            className="cls-2"
            x="708.76"
            y="95.68"
            width="57.07"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_64T">
          <path
            className="cls-1"
            d="m724.76,133.11c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m730.52,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m734.11,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m738.97,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m745.2,133.11c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m749.28,131.04v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="C_1_63"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_63")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_63")}
      >
        <g id="C_1_63S">
          <rect
            className="cls-2"
            x="646.57"
            y="185.08"
            width="57.2"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_63T">
          <path
            className="cls-1"
            d="m663.08,222.51c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m668.84,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m672.43,222.36v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m677.29,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m683.52,222.51c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m690.64,222.51c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_1_62"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_62")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_62")}
      >
        <g id="C_1_62S">
          <rect
            className="cls-2"
            x="646.57"
            y="95.68"
            width="56.99"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_62T">
          <g>
            <path
              className="cls-1"
              d="m663.1,133.11c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m668.86,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m672.44,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m677.3,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m683.54,133.11c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
            />
            <path
              className="cls-1"
              d="m687.64,132.95v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_1_61"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_61")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_61")}
      >
        <g id="C_1_61S">
          <rect
            className="cls-2"
            x="584.64"
            y="185.08"
            width="56.73"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_61T">
          <path
            className="cls-1"
            d="m601.32,222.51c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m607.08,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m610.67,222.36v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m615.53,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m621.76,222.51c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m627.34,222.36v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="C_1_60"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_60")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_60")}
      >
        <g id="C_1_60S">
          <rect
            className="cls-2"
            x="584.64"
            y="95.68"
            width="56.73"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_60T">
          <path
            className="cls-1"
            d="m599.47,133.11c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m605.23,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m608.82,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m613.67,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m619.91,133.11c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m627.97,133.11c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="C_1_59"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_59")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_59")}
      >
        <g id="C_1_59S">
          <rect
            id="C_1_59S"
            className="cls-2"
            x="522.9"
            y="185.08"
            width="56.63"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_59T">
          <path
            className="cls-1"
            d="m538.07,222.51c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m543.83,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m547.42,222.36v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m552.27,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m557.54,222.51c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m565.33,213.1c.83,0,1.55.17,2.16.52.6.35,1.07.85,1.39,1.51.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66c-.69.38-1.49.57-2.4.57-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36s.58.1.89.1c.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85-.27.23-.58.4-.93.52-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61.31-.45.72-.8,1.24-1.05.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16-.22.11-.4.26-.53.47s-.19.44-.19.72c0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7c-.12-.2-.3-.36-.54-.48-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="C_1_58"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_1_58")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_1_58")}
      >
        <g id="C_1_58S">
          <rect
            className="cls-2"
            x="522.78"
            y="95.68"
            width="56.56"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_1_58T">
          <g>
            <path
              className="cls-1"
              d="m537.77,133.11c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m543.53,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m547.12,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m551.97,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m557.24,133.11c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m565.6,133.11c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98c-.32-.42-.48-.91-.48-1.48s.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9s1.24-.31,2-.31,1.45.1,2.02.31,1.02.5,1.33.9.47.87.47,1.44-.16,1.06-.48,1.48c-.32.42-.76.75-1.34.98-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38.29-.39.7-.68,1.23-.9.53-.21,1.13-.32,1.81-.32s1.31.11,1.83.32c.52.21.93.51,1.23.9.3.39.45.85.45,1.38s-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.84-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3-.39.48-.39.84.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_1_57"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_57")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_57")}
      >
        <g id="B_1_57S">
          <rect
            className="cls-2"
            x="444.07"
            y="34.67"
            width="56.61"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_1_57T">
          <path
            className="cls-1"
            d="m453.71,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m464.21,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m467.8,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m472.66,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m477.92,72.43c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m483.98,72.28l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
        </g>
      </g>
      <g
        id="B_1_56"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_56")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_56")}
      >
        <g id="B_1_56S">
          <path
            className="cls-2"
            d="m387.67,190.81c-3.57,0-6.5-2.93-6.5-6.5v-53.95c0-3.57,2.93-6.5,6.5-6.5h43.84c3.58,0,6.5,2.93,6.5,6.5v15.03c0,3.58,2.92,6.5,6.5,6.5h11.05c3.58,0,6.5,2.93,6.5,6.5v25.93c0,3.57-2.92,6.5-6.5,6.5h-67.89Z"
          />
        </g>
        <g id="B_1_56T">
          <path
            className="cls-1"
            d="m393.92,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m404.42,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m408.01,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m412.87,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m418.14,163.15c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m426.79,163.15c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="B_1_55"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_55")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_55")}
      >
        <g id="B_1_55S">
          <rect
            className="cls-2"
            x="381.76"
            y="34.67"
            width="56.74"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_1_55T">
          <path
            className="cls-1"
            d="m391.7,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m402.2,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m405.79,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m410.64,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m415.91,72.43c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m423.59,72.43c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="B_1_54"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_54")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_54")}
      >
        <g id="B_1_54S">
          <rect
            className="cls-2"
            x="319.55"
            y="123.86"
            width="56.41"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_1_54T">
          <path
            className="cls-1"
            d="m330.34,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m340.84,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m344.43,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m349.28,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m354.55,163.15c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m359.06,161.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="B_1_53"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_53")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_53")}
      >
        <g id="B_1_53S">
          <rect
            className="cls-2"
            x="320.56"
            y="34.67"
            width="55.19"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_1_53T">
          <path
            className="cls-1"
            d="m332.06,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m342.55,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m346.14,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m351,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m356.27,72.43c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m363.81,72.43c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="B_1_52"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_52")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_52")}
      >
        <g id="B_1_52S">
          <path
            className="cls-2"
            d="m261.41,190.09c-2.75-.04-5-2.32-5-5.07v-56.16c0-2.75,2.25-5,5-5h47.56c2.75,0,5,2.25,5,5v56.95c0,2.75-2.25,4.97-5,4.93l-47.56-.65Z"
          />
        </g>
        <g id="B_1_52T">
          <path
            className="cls-1"
            d="m267.82,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m278.31,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m281.9,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m286.76,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m292.03,163.15c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m296.56,162.99v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="B_1_51"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_51")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_51")}
      >
        <g id="B_1_51S">
          <path
            className="cls-2"
            d="m313.97,94.51c0,3.57-3.35,6.5-7.43,6.5h-42.69c-4.09,0-7.43-2.93-7.43-6.5v-53.35c0-3.57,3.35-6.5,7.43-6.5h42.69c4.09,0,7.43,2.93,7.43,6.5v53.35Z"
          />
        </g>
        <g id="B_1_51T">
          <path
            className="cls-1"
            d="m268.65,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m279.15,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m282.74,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m287.59,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m292.86,72.43c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m298.86,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="B_1_50"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_50")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_50")}
      >
        <g id="B_1_50S">
          <path
            className="cls-2"
            d="m200.86,190.09c-2.75-.04-6.26-.07-7.79-.07s-2.8,2.25-2.8,5v26.53c0,2.75-2.25,5-5,5h-27.4c-2.75,0-5-2.25-5-5v-54.89c0-2.75,2.25-5,5-5h27.4c2.75,0,4.98,2.25,4.96,5l-.11,12.12c-.02,2.75,1.26,5,2.85,5s2.89-2.25,2.89-5v-49.92c0-2.75,2.25-5,5-5h44.96c2.75,0,5,2.25,5,5v56.95c0,2.75-2.25,4.97-5,4.93l-44.96-.65Z"
          />
        </g>
        <g id="B_1_50T">
          <path
            className="cls-1"
            d="m205.39,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m215.89,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m219.48,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m224.33,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m229.6,163.15c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m238.09,163.15c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="B_1_49"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_1_49")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_1_49")}
      >
        <g id="B_1_49S">
          <rect
            className="cls-2"
            x="198.37"
            y="34.67"
            width="52.6"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_1_49T">
          <path
            className="cls-1"
            d="m206.08,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m216.58,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m220.17,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m225.02,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m227.11,70.36v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m239.29,63.02c.83,0,1.55.17,2.16.52.6.35,1.07.85,1.39,1.51.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66c-.69.38-1.49.57-2.4.57-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36s.58.1.89.1c.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85-.27.23-.58.4-.93.52-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61.31-.45.72-.8,1.24-1.05.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16-.22.11-.4.26-.53.47s-.19.44-.19.72c0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7c-.12-.2-.3-.36-.54-.48-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="A_1_48"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_48")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_48")}
      >
        <g id="A_1_48S">
          <rect
            className="cls-2"
            x="133.61"
            y="34.67"
            width="56.17"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_1_48T">
          <path
            className="cls-1"
            d="m142.01,72.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m153.71,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m157.3,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m162.16,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m164.25,70.36v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m176.99,72.43c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98c-.32-.42-.48-.91-.48-1.48s.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9s1.24-.31,2-.31,1.45.1,2.02.31,1.02.5,1.33.9.47.87.47,1.44-.16,1.06-.48,1.48c-.32.42-.76.75-1.34.98-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38.29-.39.7-.68,1.23-.9.53-.21,1.13-.32,1.81-.32s1.31.11,1.83.32c.52.21.93.51,1.23.9.3.39.45.85.45,1.38s-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.84-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3-.39.48-.39.84.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="A_1_47"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_47")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_47")}
      >
        <g id="A_1_47S">
          <path
            className="cls-2"
            d="m127.19,94.51c0,3.57-2.93,6.5-6.5,6.5H56.26c-3.57,0-6.02-2.89-5.43-6.41l8.93-53.52c.59-3.53,3.99-6.41,7.57-6.41h53.36c3.57,0,6.5,2.93,6.5,6.5v53.35Z"
          />
        </g>
        <g id="A_1_47T">
          <path
            className="cls-1"
            d="m71.74,72.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m83.44,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m87.03,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m91.88,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m93.98,70.36v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m104.42,72.28l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
        </g>
      </g>
      <g
        id="A_1_46"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_46")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_46")}
      >
        <g id="A_1_46S">
          <path
            className="cls-2"
            d="m101,183.68c0,3.57-2.93,6.5-6.5,6.5h-53.39c-3.58,0-5.96-2.88-5.31-6.39l13.16-70.69c.65-3.51,4.12-6.39,7.69-6.39h37.85c3.57,0,6.5,2.92,6.5,6.5v70.47Z"
          />
        </g>
        <g id="A_1_46T">
          <path
            className="cls-1"
            d="m49.93,157.74l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m61.63,157.85c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m65.22,157.74v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m70.07,157.85c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m72.17,155.83v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m85.21,157.9c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="A_1_45"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_45")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_45")}
      >
        <g id="A_1_45S">
          <rect
            className="cls-2"
            x="33.17"
            y="196.01"
            width="68.14"
            height="56.33"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_1_45T">
          <g>
            <path
              className="cls-1"
              d="m48.04,227.98l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m59.74,228.08c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m63.33,227.98v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m68.18,228.08c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m70.27,226.07v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m82.35,228.13c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_1_44"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_44")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_44")}
      >
        <g id="A_1_44S">
          <path
            className="cls-2"
            d="m128.32,314.89c-2.75,0-5-2.25-5.01-5l-.1-73.14c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,73.14c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_1_44T">
          <path
            className="cls-1"
            d="m136.98,277.12l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m148.68,277.22c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m152.27,277.12v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m157.12,277.22c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m159.21,275.2v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m168.11,275.2v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="A_1_43"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_43")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_43")}
      >
        <g id="A_1_43S">
          <path
            className="cls-2"
            d="m101.32,307.95c0,3.98-2.93,7.24-6.5,7.24h-55.07c-3.57,0-6.5-3.26-6.5-7.24l-.42-45.36c0-3.98,2.92-7.24,6.5-7.24h55.48c3.57,0,6.5,3.26,6.5,7.24v45.36Z"
          />
        </g>
        <g id="A_1_43T">
          <g>
            <path
              className="cls-1"
              d="m47.62,288.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m59.32,288.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m62.91,288.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m67.76,288.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m69.85,286.37v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m81.79,288.44c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_1_42"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_42")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_42")}
      >
        <g id="A_1_42S">
          <path
            className="cls-2"
            d="m128.32,377.43c-2.75,0-5-2.25-5.01-5l-.09-47.05c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,47.05c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_1_42T">
          <path
            className="cls-1"
            d="m137.62,352.7l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m149.32,352.8c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m152.91,352.7v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m157.76,352.8c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m159.85,350.79v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m168.78,352.7v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="A_1_41"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_41")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_41")}
      >
        <g id="A_1_41S">
          <rect
            className="cls-2"
            x="32.83"
            y="320.82"
            width="68.48"
            height="56.68"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_1_41T">
          <path
            className="cls-1"
            d="m49.18,352.95l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m60.88,353.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m64.47,352.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m69.32,353.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m71.41,351.04v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m81.81,352.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="A_1_40"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_40")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_40")}
      >
        <g id="A_1_40S">
          <path
            className="cls-2"
            d="m128.32,440.04c-2.75,0-5-2.25-5.01-5l-.09-47.05c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,47.05c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_1_40T">
          <path
            className="cls-1"
            d="m137.04,415.31l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m148.74,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m152.33,415.31v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m157.19,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m159.28,413.4v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m172.15,415.47c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="A_1_39"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_39")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_39")}
      >
        <g id="A_1_39S">
          <path
            className="cls-2"
            d="m37.92,440.04c-2.75,0-5-2.25-5.01-5l-.1-47.39c0-2.75,2.24-5,4.99-5h58.13c2.75,0,5,2.25,4.99,5l-.09,47.39c0,2.75-2.26,5-5.01,5h-57.9Z"
          />
        </g>
        <g id="A_1_39T">
          <path
            className="cls-1"
            d="m48.49,415.31l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m60.19,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m63.78,415.31v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m68.63,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m73.76,415.47c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m81.65,406.06c.83,0,1.55.17,2.16.52.6.35,1.07.85,1.39,1.51.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66c-.69.38-1.49.57-2.4.57-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61.31-.45.72-.8,1.24-1.05.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16-.22.11-.4.26-.53.47s-.19.44-.19.72c0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7c-.12-.2-.3-.36-.54-.48-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="A_1_38"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_1_38")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_1_38")}
      >
        <g id="A_1_38S">
          <path
            className="cls-2"
            d="m37.92,501.86c-2.75,0-5-2.25-5.01-5l-.1-46.65c0-2.75,2.24-5,4.99-5h58.13c2.75,0,5,2.25,4.99,5v6.67c-.02,2.75,1.17,5,2.62,5s2.64-2.25,2.64-5v-24.19c0-2.75,2.25-5,5-5h1.82c2.75,0,5,2.25,5,5v24.19c0,2.75,1.16,5,2.58,5s2.58-2.25,2.58-5v-6.67c0-2.75,2.25-5,5-5h57.12c2.75,0,5,2.25,5,5v46.65c0,2.75-2.25,5-5,5H37.92Z"
          />
        </g>
        <g id="A_1_38T">
          <path
            className="cls-1"
            d="m93.55,483.57l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m105.25,483.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m108.84,483.57v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m113.69,483.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m118.82,483.72c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m127.28,483.72c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Floor1;
