import React from "react";

const Floor2 = ({
  onApartmentHover,
  onApartmentHoverOut,
  onApartmentClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1102.01"
      height="639.79"
      viewBox="0 0 1102.01 639.79"
    >
      <g
        id="D_2_127"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_127")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_127")}
      >
        <g id="D_2_127S">
          <path
            className="cls-2"
            d="m910.77,563.29c0-2.75,2.25-5,5-5h58.8c2.75,0,5,2.25,5,5v7.35c0,2.75,2.25,5,5,5h6.34c2.75,0,5,2.25,5,5v1.4c0,2.75,2.25,5,5,5h46.69c2.75,0,5,2.25,5,5v19.16c0,2.75-2.25,5-5,5h-131.84c-2.75,0-5-2.25-5-5v-47.9Z"
          />
        </g>
        <g id="D_2_127T">
          <path
            className="cls-1"
            d="m928.21,592.73v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m939.5,592.83c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m941.62,592.73v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m950.5,592.83c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m954.09,592.73v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m957.68,592.73v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m966.82,592.73l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
        </g>
      </g>
      <g
        id="D_2_126"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_126")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_126")}
      >
        <g id="D_2_126S">
          <path
            className="cls-2"
            d="m910.77,499.4c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v48.91c0,2.75-2.25,5-5,4.99l-57.5-.07c-2.75,0-5-2.26-5-5.01v-48.83Z"
          />
        </g>
        <g id="D_2_126T">
          <path
            className="cls-1"
            d="m922.68,527.65v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m933.96,527.75c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m936.08,527.65v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m944.97,527.75c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m948.55,527.65v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m952.14,527.65v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m963.88,527.8c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="D_2_125"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_125")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_125")}
      >
        <g id="D_2_125S">
          <path
            className="cls-2"
            d="m1001.11,500.91c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_2_125T">
          <path
            className="cls-1"
            d="m1012.77,528.57v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1024.05,528.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1026.17,528.57v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1035.06,528.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1038.65,528.57v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1042.23,528.57v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1053.01,528.72c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_2_124"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_124")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_124")}
      >
        <g id="D_2_124S">
          <path
            className="cls-2"
            d="m910.77,438.74c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v45.66c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-45.58Z"
          />
        </g>
        <g id="D_2_124T">
          <path
            className="cls-1"
            d="m922.34,465.37v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m933.63,465.47c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m935.74,465.37v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m944.63,465.47c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m948.22,465.37v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m951.81,465.37v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m959.4,463.46v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="D_2_123"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_123")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_123")}
      >
        <g id="D_2_123S">
          <path
            className="cls-2"
            d="m1001.11,438.74c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_2_123T">
          <path
            className="cls-1"
            d="m1012.75,466.06v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1024.03,466.17c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1026.15,466.06v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1035.04,466.17c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1038.63,466.06v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1042.22,466.06v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1052.84,466.22c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_2_122"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_122")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_122")}
      >
        <g id="D_2_122S">
          <path
            className="cls-2"
            d="m910.77,375.35c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v48.35c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-48.27Z"
          />
        </g>
        <g id="D_2_122T">
          <path
            className="cls-1"
            d="m922.98,403.32v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m934.26,403.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m936.38,403.32v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m945.27,403.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m948.86,403.32v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m952.45,403.32v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m960.06,403.32v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="D_2_121"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_121")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_121")}
      >
        <g id="D_2_121S">
          <path
            className="cls-2"
            d="m1001.11,376.6c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_2_121T">
          <path
            className="cls-1"
            d="m1014.08,404.26v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1025.36,404.36c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1027.48,404.26v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1036.37,404.36c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1039.96,404.26v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1043.55,404.26v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1052.64,404.26v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="D_2_120"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_120")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_120")}
      >
        <g id="D_2_120S">
          <path
            className="cls-2"
            d="m910.77,313.03c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v47.24c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-47.17Z"
          />
        </g>
        <g id="D_2_120T">
          <path
            className="cls-1"
            d="m921.33,341.37v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m932.61,341.48c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m934.73,341.37v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m943.61,341.48c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m947.2,341.37v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m950.79,341.37v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m962.36,341.53c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="D_2_119"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_119")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_119")}
      >
        <g id="D_2_119S">
          <rect
            className="cls-2"
            x="1001.12"
            y="309.33"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_2_119T">
          <path
            className="cls-1"
            d="m1013.78,341.99v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1025.06,342.09c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1027.18,341.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1036.06,342.09c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1039.65,341.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1044.72,341.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1051.56,332.73c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="D_2_118"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_118")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_118")}
      >
        <g id="D_2_118S">
          <path
            className="cls-2"
            d="m939.12,229.42c0-2.75,2.25-5,5-5h29.16c2.75,0,5,2.25,5,5v68.4c0,2.75-2.25,5-5,5h-57.5c-2.75,0-4.99-2.25-4.98-5l.14-40.82c0-2.75-2.23-5-4.98-4.99l-23.76.03c-2.75,0-5-2.24-5-4.99v-56.97c0-2.75,2.25-5,5-5h18.17c2.75,0,5.03,2.25,5.08,5l.77,50.88c.04,2.75,2.33,5,5.08,5h22.84c2.75,0,5-2.25,5-5v-11.53Z"
          />
        </g>
        <g id="D_2_118T">
          <path
            className="cls-1"
            d="m922.73,278v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m934.01,278.11c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m936.13,278v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m945.01,278.11c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m948.6,278v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m953.67,278v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m961.08,278.16c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="D_2_117"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_117")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_117")}
      >
        <g id="D_2_117S">
          <rect
            className="cls-2"
            x="1001.12"
            y="246.88"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_2_117T">
          <path
            className="cls-1"
            d="m1013.89,279.54v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1025.17,279.65c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1027.29,279.54v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1036.17,279.65c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1039.76,279.54v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1044.82,279.54v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1049.94,279.54l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
        </g>
      </g>
      <g
        id="D_2_116"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_116")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_116")}
      >
        <g id="D_2_116S">
          <rect
            className="cls-2"
            x="1001.12"
            y="184.02"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_2_116T">
          <path
            className="cls-1"
            d="m1013.78,216.68v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1025.06,216.78c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1027.18,216.68v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1036.06,216.78c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1039.65,216.68v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1044.72,216.68v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1052.42,216.83c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="D_2_115"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_115")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_115")}
      >
        <g id="D_2_115S">
          <rect
            id="D_2_115S"
            className="cls-2"
            x="972.64"
            y="95.68"
            width="51.47"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_2_115T">
          <path
            className="cls-1"
            d="m976.86,134.76v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m988.14,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m990.26,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m999.15,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1002.74,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1007.8,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1014.54,134.92c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_2_114"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_2_114")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_2_114")}
      >
        <g id="D_2_114S">
          <path
            className="cls-2"
            d="m967.43,156.13c0,3.57-2.92,6.5-6.5,6.5h-43.78c-3.57,0-6.48-2.92-6.46-6.5l.29-53.95c.02-3.57,2.96-6.5,6.54-6.5h43.41c3.58,0,6.5,2.93,6.5,6.5v53.95Z"
          />
        </g>
        <g id="D_2_114T">
          <path
            className="cls-1"
            d="m918.41,134.76v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m929.7,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m931.81,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m940.7,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m944.29,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m949.35,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m952.91,132.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="C_2_113"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_113")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_113")}
      >
        <g id="C_2_113S">
          <rect
            className="cls-2"
            x="849.28"
            y="95.68"
            width="53.92"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_113T">
          <path
            className="cls-1"
            d="m861.85,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m867.6,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m869.72,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m878.61,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m882.2,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m887.26,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m893.86,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_2_112"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_112")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_112")}
      >
        <g id="C_2_112S">
          <rect
            id="C_2_112S"
            class="cls-2"
            x="786.81"
            y="95.68"
            width="57.07"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_112T">
          <g>
            <path
              class="cls-1"
              d="m799.88,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              class="cls-1"
              d="m805.64,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              class="cls-1"
              d="m807.76,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              class="cls-1"
              d="m816.64,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              class="cls-1"
              d="m820.23,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              class="cls-1"
              d="m825.29,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              class="cls-1"
              d="m828.88,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_2_111"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_111")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_111")}
      >
        <g id="C_2_111S">
          <rect
            class="cls-2"
            x="788.11"
            y="185.08"
            width="83.87"
            height="66.97"
            rx="5"
            ry="5"
          />
        </g>
        <g id="C_2_111T">
          <g>
            <path
              class="cls-1"
              d="m816.21,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              class="cls-1"
              d="m821.97,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              class="cls-1"
              d="m824.09,223.49v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              class="cls-1"
              d="m832.97,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              class="cls-1"
              d="m836.56,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              class="cls-1"
              d="m841.63,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              class="cls-1"
              d="m846.69,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_2_110"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_110")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_110")}
      >
        <g id="C_2_110S">
          <rect
            className="cls-2"
            x="724.54"
            y="185.08"
            width="57.07"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_110T">
          <path
            className="cls-1"
            d="m737.07,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m742.83,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m744.95,223.49v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m753.83,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m757.42,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m762.48,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m770.02,223.64c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="C_2_109"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_109")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_109")}
      >
        <g id="C_2_109S">
          <rect
            className="cls-2"
            x="724.54"
            y="95.68"
            width="57.07"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_109T">
          <path
            className="cls-1"
            d="m736.79,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m742.55,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m744.67,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m753.55,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m757.14,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m764.68,134.92c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m772.75,125.51c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="C_2_108"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_108")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_108")}
      >
        <g id="C_2_108S">
          <rect
            className="cls-2"
            x="662.34"
            y="185.08"
            width="57.2"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_108T">
          <path
            className="cls-1"
            d="m674.34,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m680.09,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m682.21,223.49v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m691.1,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m694.69,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m702.23,223.64c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m710.86,223.64c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="C_2_107"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_107")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_107")}
      >
        <g id="C_2_107S">
          <rect
            className="cls-2"
            x="662.34"
            y="95.68"
            width="56.99"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_107T">
          <g>
            <path
              className="cls-1"
              d="m674.59,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m680.35,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m682.47,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m691.35,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m694.94,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m702.49,134.92c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
            <path
              className="cls-1"
              d="m708.83,134.76l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_2_106"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_106")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_106")}
      >
        <g id="C_2_106S">
          <rect
            className="cls-2"
            x="600.41"
            y="185.08"
            width="56.73"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_106T">
          <path
            className="cls-1"
            d="m611.43,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m617.19,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m619.31,223.49v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m628.19,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m631.78,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m639.32,223.64c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m648.26,223.64c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="C_2_105"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_105")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_105")}
      >
        <g id="C_2_105S">
          <rect
            className="cls-2"
            x="600.41"
            y="95.68"
            width="56.73"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_105T">
          <path
            className="cls-1"
            d="m611.7,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m617.46,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m619.58,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m628.47,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m632.05,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m639.6,134.92c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m647.56,134.92c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_2_104"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_104")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_104")}
      >
        <g id="C_2_104S">
          <rect
            className="cls-2"
            x="538.67"
            y="185.08"
            width="56.63"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_104T">
          <path
            className="cls-1"
            d="m549.59,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m555.35,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m557.47,223.49v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m566.35,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m569.94,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m577.48,223.64c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m582.27,221.57v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="C_2_103"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_2_103")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_2_103")}
      >
        <g id="C_2_103S">
          <rect
            className="cls-2"
            x="538.56"
            y="95.68"
            width="56.56"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_2_103T">
          <g>
            <path
              className="cls-1"
              d="m550.22,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m555.98,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m558.09,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m566.98,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m570.57,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m578.11,134.92c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
            <path
              className="cls-1"
              d="m585.93,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_2_102"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_102")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_102")}
      >
        <g id="B_2_102S">
          <rect
            className="cls-2"
            x="459.84"
            y="34.67"
            width="56.61"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_2_102T">
          <path
            className="cls-1"
            d="m465.33,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m475.82,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m477.94,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m486.83,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m490.42,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m497.96,72.43c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m502.77,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="B_2_101"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_101")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_101")}
      >
        <g id="B_2_101S">
          <path
            className="cls-2"
            d="m403.44,190.81c-3.57,0-6.5-2.93-6.5-6.5v-53.95c0-3.57,2.93-6.5,6.5-6.5h43.84c3.57,0,6.5,2.93,6.5,6.5v15.03c0,3.57,2.92,6.5,6.5,6.5h11.05c3.58,0,6.5,2.93,6.5,6.5v25.93c0,3.57-2.92,6.5-6.5,6.5h-67.89Z"
          />
        </g>
        <g id="B_2_101T">
          <path
            className="cls-1"
            d="m406.92,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m417.42,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m419.54,162.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m428.42,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m432.01,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m439.55,163.15c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m445.84,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="B_2_100"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_100")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_100")}
      >
        <g id="B_2_100S">
          <rect
            className="cls-2"
            x="397.53"
            y="34.67"
            width="56.74"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_2_100T">
          <path
            className="cls-1"
            d="m402.57,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m413.07,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m415.19,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m424.07,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m427.66,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m435.21,72.43c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m443.97,72.43c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="B_2_99"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_99")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_99")}
      >
        <g id="B_2_99S">
          <rect
            className="cls-2"
            x="335.32"
            y="123.86"
            width="56.41"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_2_99T">
          <path
            className="cls-1"
            d="m344.9,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m355.4,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m357.51,162.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m366.4,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m371.77,153.74c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m380,153.74c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="B_2_98"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_98")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_98")}
      >
        <g id="B_2_98S">
          <rect
            className="cls-2"
            x="336.33"
            y="34.67"
            width="55.19"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_2_98T">
          <g>
            <path
              className="cls-1"
              d="m345.84,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m356.34,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m358.45,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m367.34,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m372.71,63.02c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
            <path
              className="cls-1"
              d="m381.5,72.43c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_2_97"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_97")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_97")}
      >
        <g id="B_2_97S">
          <path
            className="cls-2"
            d="m277.18,190.09c-2.75-.04-5-2.32-5-5.07v-56.16c0-2.75,2.25-5,5-5h47.56c2.75,0,5,2.25,5,5v56.95c0,2.75-2.25,4.97-5,4.93l-47.56-.65Z"
          />
        </g>
        <g id="B_2_97T">
          <path
            className="cls-1"
            d="m281.84,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m292.34,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m294.46,162.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m303.34,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m308.72,153.74c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m315.21,162.99l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
        </g>
      </g>
      <g
        id="B_2_96"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_96")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_96")}
      >
        <g id="B_2_96S">
          <path
            className="cls-2"
            d="m329.74,94.51c0,3.58-3.35,6.5-7.43,6.5h-42.69c-4.09,0-7.43-2.92-7.43-6.5v-53.35c0-3.57,3.35-6.5,7.43-6.5h42.69c4.09,0,7.43,2.93,7.43,6.5v53.35Z"
          />
        </g>
        <g id="B_2_96T">
          <path
            className="cls-1"
            d="m281.29,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m291.79,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m293.9,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m302.79,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m308.16,63.02c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m317.25,72.43c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="B_2_95"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_95")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_95")}
      >
        <g id="B_2_95S">
          <path
            className="cls-2"
            d="m216.63,190.09c-2.75-.04-6.26-.07-7.79-.07s-2.8,2.25-2.8,5v26.53c0,2.75-2.25,5-5,5h-27.4c-2.75,0-5-2.25-5-5v-54.89c0-2.75,2.25-5,5-5h27.4c2.75,0,4.98,2.25,4.96,5l-.11,12.12c-.02,2.75,1.26,5,2.85,5s2.89-2.25,2.89-5v-49.92c0-2.75,2.25-5,5-5h44.96c2.75,0,5,2.25,5,5v56.95c0,2.75-2.25,4.97-5,4.93l-44.96-.65Z"
          />
        </g>
        <g id="B_2_95T">
          <path
            className="cls-1"
            d="m220.16,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m230.65,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m232.77,162.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m241.66,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m247.03,153.74c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m255.15,163.15c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="B_2_94"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_2_94")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_2_94")}
      >
        <g id="B_2_94S">
          <rect
            className="cls-2"
            x="214.14"
            y="34.67"
            width="52.6"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_2_94T">
          <path
            className="cls-1"
            d="m220.57,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m231.07,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m233.19,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m242.07,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m247.44,63.02c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m252.39,70.37v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="A_2_93"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_93")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_93")}
      >
        <g id="A_2_93S">
          <rect
            className="cls-2"
            x="149.39"
            y="34.67"
            width="56.17"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_2_93T">
          <path
            className="cls-1"
            d="m157.28,72.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m168.98,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m171.1,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m179.98,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m185.35,63.02c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m193.33,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="A_2_92"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_92")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_92")}
      >
        <g id="A_2_92S">
          <path
            className="cls-2"
            d="m142.96,94.51c0,3.58-2.92,6.5-6.5,6.5h-64.43c-3.57,0-6.02-2.89-5.43-6.41l8.93-53.52c.59-3.53,3.99-6.41,7.57-6.41h53.36c3.58,0,6.5,2.93,6.5,6.5v53.35Z"
          />
        </g>
        <g id="A_2_92T">
          <path
            className="cls-1"
            d="m86.76,72.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m98.46,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m100.58,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m109.46,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m114.84,63.02c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m119.81,72.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="A_2_91"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_91")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_91")}
      >
        <g id="A_2_91S">
          <path
            className="cls-2"
            d="m116.77,183.68c0,3.58-2.92,6.5-6.5,6.5h-53.39c-3.57,0-5.96-2.88-5.31-6.39l13.16-70.69c.65-3.51,4.12-6.39,7.69-6.39h37.85c3.58,0,6.5,2.93,6.5,6.5v70.47Z"
          />
        </g>
        <g id="A_2_91T">
          <path
            className="cls-1"
            d="m66.34,157.74l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m78.04,157.85c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m80.16,157.74v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m89.04,157.85c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m94.41,148.49c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
          <path
            className="cls-1"
            d="m100.86,157.74v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="A_2_90"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_90")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_90")}
      >
        <g id="A_2_90S">
          <rect
            className="cls-2"
            x="48.95"
            y="196.01"
            width="68.14"
            height="56.33"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_2_90T">
          <g>
            <path
              className="cls-1"
              d="m62.32,227.98l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m74.02,228.08c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m76.14,227.98v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m85.02,228.08c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m90.4,218.72c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
            />
            <path
              className="cls-1"
              d="m99.32,228.13c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_2_89"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_89")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_89")}
      >
        <g id="A_2_89S">
          <path
            className="cls-2"
            d="m144.09,314.89c-2.75,0-5-2.25-5.01-5l-.1-73.14c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,73.14c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_2_89T">
          <path
            className="cls-1"
            d="m151.99,277.12l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m163.69,277.22c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m165.81,277.12v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m174.69,277.22c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m180.63,277.27c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m188.59,267.86c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="A_2_88"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_88")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_88")}
      >
        <g id="A_2_88S">
          <path
            className="cls-2"
            d="m117.09,307.95c0,3.98-2.93,7.24-6.5,7.24h-55.07c-3.57,0-6.5-3.26-6.5-7.24l-.42-45.36c0-3.98,2.92-7.24,6.5-7.24h55.48c3.57,0,6.5,3.26,6.5,7.24v45.36Z"
          />
        </g>
        <g id="A_2_88T">
          <g>
            <path
              className="cls-1"
              d="m61.86,288.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m73.56,288.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m75.68,288.28v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
            <path
              className="cls-1"
              d="m84.56,288.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m90.5,288.44c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
            <path
              className="cls-1"
              d="m99.02,288.44c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_2_87"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_87")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_87")}
      >
        <g id="A_2_87S">
          <path
            className="cls-2"
            d="m144.09,377.43c-2.75,0-5-2.25-5.01-5l-.09-47.05c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,47.05c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_2_87T">
          <path
            className="cls-1"
            d="m152.1,352.7l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m163.8,352.8c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m165.92,352.7v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m174.8,352.8c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m180.74,352.86c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m186.97,352.7l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
        </g>
      </g>
      <g
        id="A_2_86"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_86")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_86")}
      >
        <g id="A_2_86S">
          <rect
            className="cls-2"
            x="48.6"
            y="320.82"
            width="68.48"
            height="56.68"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_2_86T">
          <path
            className="cls-1"
            d="m62.27,352.95l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m73.97,353.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m76.09,352.95v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m84.97,353.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m90.91,353.11c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m99.73,353.11c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="A_2_85"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_85")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_85")}
      >
        <g id="A_2_85S">
          <path
            className="cls-2"
            d="m144.09,440.04c-2.75,0-5-2.25-5.01-5l-.09-47.05c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,47.05c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_2_85T">
          <path
            className="cls-1"
            d="m152.26,415.31l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m163.96,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m166.08,415.31v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m174.97,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m180.91,415.47c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m188.76,415.47c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="A_2_84"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_84")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_84")}
      >
        <g id="A_2_84S">
          <path
            className="cls-2"
            d="m53.69,440.04c-2.75,0-5-2.25-5.01-5l-.1-47.39c0-2.75,2.24-5,4.99-5h58.13c2.75,0,5,2.25,4.99,5l-.09,47.39c0,2.75-2.26,5-5.01,5h-57.9Z"
          />
        </g>
        <g id="A_2_84T">
          <path
            className="cls-1"
            d="m62.21,415.31l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m73.9,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m76.02,415.31v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m84.91,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m90.85,415.47c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m95.52,413.4v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="A_2_83"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_2_83")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_2_83")}
      >
        <g id="A_2_83S">
          <path
            className="cls-2"
            d="m53.69,501.86c-2.75,0-5-2.25-5.01-5l-.1-46.65c0-2.75,2.24-5,4.99-5h58.13c2.75,0,5,2.25,4.99,5v6.67c-.02,2.75,1.17,5,2.62,5s2.64-2.25,2.64-5v-24.19c0-2.75,2.25-5,5-5h1.82c2.75,0,5,2.25,5,5v24.19c0,2.75,1.16,5,2.58,5s2.58-2.25,2.58-5v-6.67c0-2.75,2.25-5,5-5h57.12c2.75,0,5,2.25,5,5v46.65c0,2.75-2.25,5-5,5H53.69Z"
          />
        </g>
        <g id="A_2_83T">
          <path
            className="cls-1"
            d="m108.04,483.57l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m119.74,483.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m121.86,483.57v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m130.74,483.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m136.68,483.72c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
          <path
            className="cls-1"
            d="m144.39,483.72c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Floor2;
