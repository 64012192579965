import "./styles.scss";

import React from "react";

import ListArrow from "assets/icons/list-arrow.svg";

const mainClass = "apts-list";

const ListHeadItem = ({ label, fn_down, fn_up }) => {
  return (
    <div className={`${mainClass}__table-head-item`}>
      <p>{label}</p>
      {(fn_down !== null || fn_up !== null) && (
        <div className={`${mainClass}__table-head-buttons`}>
          <button type="button" onClick={fn_up}>
            <ListArrow />
          </button>
          <button type="button" onClick={fn_down}>
            <ListArrow />
          </button>
        </div>
      )}
    </div>
  );
};

export default ListHeadItem;
