import "./styles.scss";

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { Fade } from "react-awesome-reveal";
import MouseTooltip from "react-sticky-mouse-tooltip";

import {
  Floor0,
  Floor1,
  Floor2,
  Floor3,
  Floor4,
} from "page_components/apartments/SingleFloor/components";

import { useApartmentList } from "../../../context/apartments-list";

import scrollToElement from "utils/scrollToElement";

import ArrowIcon from "assets/icons/arrow-thin.svg";
import CloseIcon from "assets/icons/x-mark.svg";
import Compass from "assets/icons/compass.svg";

const mainClass = "single-floor";

const SingleFloor = () => {
  const { showFloorCard, setShowFloorCard, setShowRoomCard, formatted_data } =
    useApartmentList();

  const [tolltipVisible, setTolltipVisible] = useState(false);
  const [hoveredApartment, setHoveredApartment] = useState({
    apartmentNumber: "",
    status: "",
    size: 0,
    rooms: 0,
  });

  const getClassByStatus = (status) => {
    switch (status) {
      case "1":
        return "available";
      case "2":
        return "booked";
      default:
        return "sold";
    }
  };

  const onApartmentHover = (apartmentId) => {
    const hovered_apt_data = formatted_data?.find(
      (apt) =>
        apt.nieruchomosci.nameBuilding?.substring(4) ===
        apartmentId.replaceAll("_", ".")
    );
    if (hovered_apt_data) {
      const { nameBuilding, rooms, area, statusId } =
        hovered_apt_data.nieruchomosci;
      setHoveredApartment({
        apartmentNumber: nameBuilding?.substring(4),
        status: statusId,
        size: area,
        rooms: rooms,
      });
      setTolltipVisible(true);
    }
  };

  const onApartmentHoverOut = () => {
    setTolltipVisible(false);
    setHoveredApartment({ ...hoveredApartment, apartmentNumber: "" });
  };

  const onApartmentClick = (apartmentId) => {
    setShowRoomCard(apartmentId);
  };

  useEffect(() => {
    const apartment_elements = document.querySelectorAll(".floor-view__item");

    apartment_elements.forEach((item) => {
      if (item.getAttribute("id")) {
        const el = formatted_data?.find(
          (apt) =>
            apt.nieruchomosci.nameBuilding?.substring(4) ===
            item.getAttribute("id").replaceAll("_", ".")
        );

        if (el?.nieruchomosci?.statusId) {
          const status_class = getClassByStatus(el?.nieruchomosci?.statusId);

          item.classList.add(status_class);
        }
      }
    });
  });

  const floorsViewData = {
    hoveredApartment,
    showFloorCard,
    onApartmentHover,
    onApartmentHoverOut,
    onApartmentClick,
  };

  const floors_data = [
    {
      floor: 0,
      img: require("assets/floors/FOREST_pietro_0_rzut.png").default,
      svg: <Floor0 {...floorsViewData} />,
    },
    {
      floor: 1,
      img: require("assets/floors/FOREST_pietro_1_rzut.png").default,
      svg: <Floor1 {...floorsViewData} />,
    },
    {
      floor: 2,
      img: require("assets/floors/FOREST_pietro_2_rzut.png").default,
      svg: <Floor2 {...floorsViewData} />,
    },
    {
      floor: 3,
      img: require("assets/floors/FOREST_pietro_3_rzut.png").default,
      svg: <Floor3 {...floorsViewData} />,
    },
    {
      floor: 4,
      img: require("assets/floors/FOREST_pietro_4_rzut.png").default,
      svg: <Floor4 {...floorsViewData} />,
    },
  ];

  return (
    <section id="single-floor-card" className={mainClass}>
      {showFloorCard !== null && (
        <div className="container-fluid">
          <div className={`${mainClass}__wrapper`}>
            <div className={`${mainClass}__top`}>
              <div className={`${mainClass}__button--wrapper`}>
                <button
                  type="button"
                  className={`${mainClass}__button--prev`}
                  onClick={() => {
                    setShowFloorCard(null);
                    setShowRoomCard(null);
                    scrollToElement("#choose-floor");
                  }}
                >
                  <ArrowIcon />
                  <span>POWRÓT DO WIDOKU BUDYNKU</span>
                </button>
              </div>
              <button
                type="button"
                className={`${mainClass}__button--close`}
                onClick={() => {
                  setShowFloorCard(null);
                  setShowRoomCard(null);
                  scrollToElement("#choose-floor");
                }}
              >
                <CloseIcon />
              </button>
            </div>
            <div className={`${mainClass}__main`}>
              <div className={`${mainClass}__col-left`}>
                <p className={`${mainClass}__title`}>
                  Wybierz swój
                  <br />
                  <span>apartament</span>
                </p>
                <div className={`${mainClass}__logo`}>
                  <img
                    src={require("assets/logo-white-dark.svg").default}
                    alt=""
                  />
                </div>
                <div className={`${mainClass}__details`}>
                  <div className={`${mainClass}__details-row`}>
                    <p>Dostępne</p>
                    <span></span>
                  </div>
                  <div className={`${mainClass}__details-row`}>
                    <p>Zarezerwowane</p>
                    <span></span>
                  </div>
                  <div className={`${mainClass}__details-row`}>
                    <p>Sprzedane</p>
                    <span></span>
                  </div>
                </div>
                <p className={`${mainClass}__text`}>
                  Przewidywany termin zakończenia inwestycji w stanie
                  deweloperskim:
                  <br />
                  <span>4Q2023.</span>
                </p>
                <p className={`${mainClass}__size`}>Powierzchnie: 30–80 m2</p>
                <div className={`${mainClass}__compass`}>
                  <Compass />
                </div>
              </div>
              <div className={`${mainClass}__col-center`}>
                <MouseTooltip
                  visible={tolltipVisible}
                  offsetX={-70}
                  offsetY={-100}
                  className={classnames(`${mainClass}__tooltip`, {
                    available: hoveredApartment?.status === "1",
                    booked: hoveredApartment?.status === "2",
                    sold:
                      hoveredApartment?.status !== "1" &&
                      hoveredApartment?.status !== "2",
                  })}
                >
                  <div className={`${mainClass}__tooltip-content`}>
                    <span className={`${mainClass}__tooltip-id`}>
                      {hoveredApartment?.apartmentNumber}
                    </span>
                    <div className={`${mainClass}__tooltip-details`}>
                      <p className={`${mainClass}__tooltip-size`}>
                        Pow: <b>{hoveredApartment?.size}</b> m<sup>2</sup>
                      </p>
                      <p className={`${mainClass}__tooltip-rooms`}>
                        Liczba pokoi: <b>{hoveredApartment?.rooms}</b>
                      </p>
                    </div>
                  </div>
                </MouseTooltip>
                {floors_data
                  .filter((item) => showFloorCard === item.floor)
                  .map(({ img, svg, floor }) => {
                    return (
                      <Fade duration={900} key={floor}>
                        <div className={`${mainClass}__project`}>
                          <img src={img} alt="" />
                          {svg}
                        </div>
                      </Fade>
                    );
                  })}
              </div>
              <div className={`${mainClass}__col-right`}>
                <p className={`${mainClass}__size`}>Piętro</p>
                <div className={`${mainClass}__counter`}>
                  {Array(5)
                    .fill("")
                    .map((item, index) => {
                      return (
                        <button
                          key={index}
                          className={classnames(`${mainClass}__counter--row`, {
                            active: 4 - index === showFloorCard,
                          })}
                          onClick={() => {
                            setShowFloorCard(4 - index);
                            setShowRoomCard(null);
                          }}
                        >
                          <span></span>
                          <p>{4 - index}</p>
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default SingleFloor;
