import "./styles.scss";

import React from "react";

import MessageIcon from "assets/icons/message.svg";
import PdfIcon from "assets/icons/pdf.svg";
import ArrowIcon from "assets/icons/arrow-thin.svg";
import CloseIcon from "assets/icons/x-mark.svg";

import { useApartmentList } from "../../../context/apartments-list";

import scrollToElement from "utils/scrollToElement";

const mainClass = "single-room";
let room_data;
let image;

const images_list = [
  "C_0_13",
  "C_0_14",
  "C_0_16",
  "C_0_18",
  "C_0_21",
  "C_1_66",
  "D_1_71",
  "D_1_73",
  "D_1_74",
  "D_1_76",
  "D_1_78",
  "D_1_80",
  "C_2_104",
  "C_2_106",
  "C_2_110",
  "C_2_111",
  "D_2_116",
  "D_2_117",
  "D_2_118",
  "D_2_120",
  "D_2_121",
  "D_2_122",
  "D_2_123",
  "D_2_125",
  "D_2_127",
  "C_3_148",
  "C_3_149",
  "C_3_151",
  "C_3_153",
  "C_3_155",
  "D_3_162",
  "D_3_163",
  "D_3_164",
  "D_3_165",
  "D_3_166",
  "D_3_167",
  "D_3_168",
  "D_3_170",
  "C_4_192",
  "C_4_194",
  "C_4_195",
  "C_4_196",
  "C_4_197",
  "C_4_198",
  "C_4_199",
  "C_4_200",
  "C_4_202",
  "D_4_204",
  "D_4_206",
  "D_4_207",
  "D_4_208",
  "D_4_209",
  "D_4_211",
  "D_4_212",
  "D_4_213",
  "B_1_50",
  "A_2_83",
  "B_2_95",
  "A_3_134",
  "B_3_140",
  "A_4_173",
  "A_4_176",
  "A_4_178",
  "A_4_179",
  "A_4_180",
];

const SingleRoom = ({ setContactModalOpen }) => {
  const {
    showRoomCard,
    setShowRoomCard,
    formatted_data,
    setAptFromListNumber,
  } = useApartmentList();

  if (images_list?.includes(showRoomCard)) {
    image = require(`assets/plans/${showRoomCard}.jpg`).default;
  }

  if (showRoomCard) {
    room_data = formatted_data?.find(
      (apt) =>
        apt.nieruchomosci.nameBuilding?.substring(4) ===
        showRoomCard.replaceAll("_", ".")
    );
    if (
      room_data.nieruchomosci.statusId !== "1" &&
      room_data.nieruchomosci.statusId !== "2"
    ) {
      setShowRoomCard(null);
    }

    if (
      room_data.nieruchomosci.statusId === "1" ||
      room_data.nieruchomosci.statusId === "2"
    ) {
      scrollToElement("#single-room-card");
    }
  }

  return (
    <section id="single-room-card" className={mainClass}>
      {showRoomCard !== null && (
        <div className="container-fluid">
          <div className={`${mainClass}__wrapper`}>
            <div className={`${mainClass}__top`}>
              <div className={`${mainClass}__button--wrapper`}>
                <button
                  type="button"
                  className={`${mainClass}__button--prev`}
                  onClick={() => {
                    setShowRoomCard(null);
                    scrollToElement("#single-floor-card");
                  }}
                >
                  <ArrowIcon />
                  <span>POWRÓT DO WIDOKU PIĘTRA</span>
                </button>
              </div>
              <button
                type="button"
                className={`${mainClass}__button--close`}
                onClick={() => {
                  setShowRoomCard(null);
                  scrollToElement("#single-floor-card");
                }}
              >
                <CloseIcon />
              </button>
            </div>
            <div className={`${mainClass}__main`}>
              <div className="container">
                <div className={`${mainClass}__main--wrapper`}>
                  <div className={`${mainClass}__col-left`}>
                    <p className={`${mainClass}__id`}>
                      {room_data?.nieruchomosci?.nameBuilding?.substring(4)}
                    </p>
                    <div className={`${mainClass}__details`}>
                      <div className={`${mainClass}__details--row`}>
                        <p>Piętro:</p>
                        <p>{room_data?.nieruchomosci?.floor}</p>
                      </div>
                      <div className={`${mainClass}__details--row`}>
                        <p>Liczba pokoi:</p>
                        <p>{room_data?.nieruchomosci?.rooms}</p>
                      </div>
                      <div className={`${mainClass}__details--row`}>
                        <p>Powierzchnia:</p>
                        <p className="transform">
                          {room_data?.nieruchomosci?.area} m<sup>2</sup>
                        </p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className={`${mainClass}__btn-contact`}
                      onClick={() => {
                        setContactModalOpen(true);
                        setAptFromListNumber(
                          room_data?.nieruchomosci?.nameBuilding?.substring(4)
                        );
                      }}
                    >
                      <span>zapytaj</span>
                      <MessageIcon />
                    </button>
                    {room_data?.nieruchomosci?.cardLink && (
                      <a
                        href={room_data?.nieruchomosci?.cardLink}
                        target="_blank"
                        rel="noreferrer"
                        className={`${mainClass}__btn-pdf`}
                      >
                        <span>pobierz rzut</span>
                        <PdfIcon />
                      </a>
                    )}
                    {room_data?.nieruchomosci?.price ? (
                      <>
                        <p className={`${mainClass}__text`}>
                          Cena łączna netto:
                        </p>
                        <p className={`${mainClass}__price`}>
                          {room_data?.nieruchomosci?.price} <span>zł</span>
                        </p>
                      </>
                    ) : null}
                  </div>
                  <div className={`${mainClass}__col-right`}>
                    <div className={`${mainClass}__image`}>
                      {image && <img src={image} alt="" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default SingleRoom;
