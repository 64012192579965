import React from "react";

import scrollToElement from "utils/scrollToElement";

import { useApartmentList } from "../../../../context/apartments-list";

const BuiildingFloors = ({ onFloorHover, onFloorHoverOut }) => {
  const { setShowFloorCard } = useApartmentList();

  const clickHandler = (id) => {
    setShowFloorCard(id);
    scrollToElement("#single-floor-card");
  };

  return (
    <svg
      className="building-floors"
      xmlns="http://www.w3.org/2000/svg"
      width="1920"
      height="1273"
      viewBox="0 0 1920 1273"
    >
      <g className="building-floors__wrapper">
        <g
          id="A_0"
          onClick={() => clickHandler(0)}
          onMouseEnter={() => onFloorHover("A_0")}
          onMouseLeave={() => onFloorHoverOut()}
          className="building-floors__item"
        >
          <g>
            <polygon points="696.78 960.71 886.93 956.7 854.69 956.26 854.69 954.42 1238.35 941.68 1397.72 952.72 1431.03 952.55 1475.54 952.72 1767.62 974.81 1767.62 973.11 1775.6 973.11 1777.57 973.11 1777.57 1042.44 1475.15 1075.27 1395.01 1072.27 1291.07 1078.09 1291.26 1083.64 1239.44 1082.78 1239.5 1084.17 696.81 1057.44 696.78 960.71" />
            <polygon points="151.89 986.43 151.89 984.56 254.51 982.03 602.24 985.88 696.81 984.45 696.78 1026.68 602.24 1026.68 254.51 1028.55 193.36 1025.44 193.36 989.21 162.73 989.33 162.61 1026.31 159 1026.56 154.14 1025.81 154.02 988.96 151.89 988.91 151.89 986.43" />
          </g>
        </g>
        <g
          id="A_1"
          onClick={() => clickHandler(1)}
          onMouseEnter={() => onFloorHover("A_1")}
          onMouseLeave={() => onFloorHoverOut()}
          className="building-floors__item"
        >
          <g>
            <polygon points="696.81 863.23 886.93 842.88 849.96 842.88 849.96 836.8 1238.47 792.88 1397.69 830.59 1430.63 829.29 1475.13 827.99 1768.14 906.15 1767.9 904.32 1776.25 904.32 1777.57 904.32 1777.57 973.11 1775.6 973.11 1767.62 973.11 1767.62 974.81 1475.54 952.72 1431.03 952.55 1397.72 952.72 1238.35 941.68 854.69 954.42 854.69 956.26 886.93 956.7 696.78 960.71 696.81 863.23" />
            <polygon points="151.89 947.26 151.89 945.19 254.75 937.51 602.24 948.56 696.78 944.73 696.81 984.45 602.24 985.88 254.51 982.03 151.89 984.56 151.89 986.43 141.56 986.43 141.56 984.9 145.63 984.45 145.63 970.16 151.78 969.94 151.89 947.26" />
          </g>
        </g>
        <g
          id="A_2"
          onClick={() => clickHandler(2)}
          onMouseEnter={() => onFloorHover("A_2")}
          onMouseLeave={() => onFloorHoverOut()}
          className="building-floors__item"
        >
          <g>
            <polygon points="696.78 765.62 886.93 731.13 841.82 721.21 1236.67 643.58 1402.42 709.46 1475.68 700.43 1475.75 705.04 1777.57 842.88 1777.57 904.32 1776.25 904.32 1767.9 904.32 1768.14 906.15 1475.13 827.99 1430.63 829.29 1397.69 830.59 1238.47 792.88 849.96 836.8 849.96 842.88 886.93 842.88 696.81 863.23 696.78 765.62" />
            <polygon points="151.33 909.09 151.33 907.39 254.51 894.59 602.24 914.01 696.81 907.57 696.78 944.73 602.24 948.56 254.75 937.51 151.89 945.19 151.89 947.26 141.56 947.49 141.56 945.77 145.46 945.31 145.69 931.05 151.43 930.67 151.33 909.09" />
          </g>
        </g>
        <g
          id="A_3"
          onClick={() => clickHandler(3)}
          onMouseEnter={() => onFloorHover("A_3")}
          onMouseLeave={() => onFloorHoverOut()}
          className="building-floors__item"
        >
          <g>
            <polygon points="696.81 867.79 696.81 907.57 602.24 914.01 254.51 894.59 151.33 907.39 151.33 909.09 141.56 908.71 141.56 906.7 145.77 906.22 145.77 891.97 151.61 891.39 151.33 867.87 254.14 849.29 601.71 877.57 696.81 867.79" />
            <polygon points="843.03 604.28 1237.32 494.48 1403.15 586.89 1475.82 573.9 1479.47 576.08 1479.5 579 1481.76 580.47 1777.57 772.12 1777.57 842.88 1475.75 705.04 1475.68 700.43 1402.42 709.46 1236.67 643.58 841.82 721.21 886.93 731.13 696.78 765.62 696.78 668.68 754.8 653.44 891.66 619.61 843.03 604.28" />
          </g>
        </g>
        <g
          id="A_4"
          onClick={() => clickHandler(4)}
          onMouseEnter={() => onFloorHover("A_4")}
          onMouseLeave={() => onFloorHoverOut()}
          className="building-floors__item"
        >
          <g>
            <polygon points="1777.57 772.12 1481.76 580.47 1479.5 579 1479.47 576.08 1475.82 573.9 1403.15 586.89 1237.32 494.48 843.03 604.28 891.66 619.61 754.8 653.44 696.78 668.68 696.78 666.61 714.28 661.7 714.28 629.28 753.15 630.85 762.69 619.05 762.69 615.38 764.33 615.38 764.46 617.28 779.14 599.05 779.27 551.97 778.13 551.47 778 546.4 837.86 526.91 937.21 404.16 1051.99 455.79 1200.57 406.05 1295.36 466.42 1295.36 473.26 1294.56 473.57 1294.6 476.76 1308.88 485.19 1375.12 467.11 1444.56 516.57 1444.56 522.18 1443.66 522.72 1443.57 530.04 1457.68 526.61 1460.66 528.96 1461.93 528.78 1517.72 568.11 1517.72 569.29 1530.29 578.15 1531.46 577.88 1576.58 609.62 1576.67 610.61 1586.89 618.02 1587.98 617.84 1633.73 651.12 1634.64 651.21 1673.25 678.7 1674.51 678.97 1700.55 697.33 1700.64 698.23 1761.23 741.09 1761.5 760.71 1768.73 765.6 1776.42 765.05 1777.57 765.69 1777.57 772.12" />
            <polygon points="151.33 867.87 254.14 849.29 601.71 877.57 696.81 867.79 696.78 821.81 630.69 831.1 191.54 780.1 191.13 781.45 170.28 821.81 164.49 823.16 164.76 831.9 152.25 852.22 152.25 855.99 145.25 856.66 145.25 866.62 141.56 867.83 141.56 869.31 151.36 870.11 151.33 867.87" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BuiildingFloors;
