import React from "react";

const Floor0 = ({
  onApartmentHover,
  onApartmentHoverOut,
  onApartmentClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1133.32"
      height="662.35"
      viewBox="0 0 1133.32 662.35"
    >
      <g
        id="D_0_37"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_37")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_37")}
      >
        <g id="D_0_37S">
          <path
            className="cls-2"
            d="m981.59,580.89c3.58,0,6.5,2.93,6.5,6.5v4.35c0,3.58,2.92,6.5,6.5,6.5h2.04c3.58,0,6.5,2.85,6.5,6.33s2.93,6.33,6.5,6.33h43.69c3.57,0,6.5,2.93,6.5,6.5v14.84c0,3.57-2.93,6.5-6.5,6.5h-129.43c-3.57,0-6.5-2.93-6.5-6.5v-44.85c0-3.57,2.93-6.5,6.5-6.5h57.7Z"
          />
        </g>
        <g id="D_0_37T">
          <path
            className="cls-1"
            d="m932.16,615.29v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m943.45,615.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m949.51,615.44c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m955.6,615.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m960.72,615.44c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m966.88,615.29l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
        </g>
      </g>
      <g
        id="D_0_35"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_35")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_35")}
      >
        <g id="D_0_35S">
          <rect
            class="cls-2"
            x="1008.33"
            y="518.47"
            width="67.75"
            height="39.81"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_35T">
          <path
            class="cls-1"
            d="m1022.15,542.92v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            class="cls-1"
            d="m1033.44,543.02c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m1039.51,543.08c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            class="cls-1"
            d="m1045.59,543.02c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m1050.71,543.08c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
          <path
            class="cls-1"
            d="m1058.5,543.08c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_0_36"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_36")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_36")}
      >
        <g id="D_0_36S">
          <rect
            className="cls-2"
            x="917.39"
            y="518.47"
            width="68.1"
            height="57.22"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_36T">
          <path
            class="cls-1"
            d="m931.07,551.62v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            class="cls-1"
            d="m942.35,551.73c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m948.42,551.78c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            class="cls-1"
            d="m954.5,551.73c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m959.63,551.78c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
          <path
            class="cls-1"
            d="m968.39,551.78c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="D_0_33"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_33")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_33")}
      >
        <g id="D_0_33S">
          <rect
            className="cls-2"
            x="1008.33"
            y="456.39"
            width="67.75"
            height="56.7"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_33T">
          <path
            class="cls-1"
            d="m1022.23,489.29v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            class="cls-1"
            d="m1033.52,489.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m1039.59,489.44c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            class="cls-1"
            d="m1045.67,489.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m1050.79,489.44c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
          <path
            class="cls-1"
            d="m1058.44,489.44c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_0_34"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_34")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_34")}
      >
        <g id="D_0_34S">
          <rect
            className="cls-2"
            x="917.39"
            y="456.39"
            width="68.1"
            height="56.7"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_34T">
          <path
            class="cls-1"
            d="m930.66,489.29v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            class="cls-1"
            d="m941.95,489.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m948.02,489.44c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            class="cls-1"
            d="m954.1,489.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m959.22,489.44c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
          <path
            class="cls-1"
            d="m963.83,487.38v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="D_0_31"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_31")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_31")}
      >
        <g id="D_0_31S">
          <rect
            className="cls-2"
            x="1008.33"
            y="394.34"
            width="67.75"
            height="56.84"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_31T">
          <path
            class="cls-1"
            d="m1023.83,427.31v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            class="cls-1"
            d="m1035.11,427.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m1041.18,427.47c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            class="cls-1"
            d="m1047.26,427.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m1052.39,427.47c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
          <path
            class="cls-1"
            d="m1058.5,427.31v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="D_0_32"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_32")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_32")}
      >
        <g id="D_0_32S">
          <rect
            className="cls-2"
            x="917.39"
            y="394.34"
            width="68.1"
            height="56.79"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_32T">
          <path
            class="cls-1"
            d="m931.42,427.28v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            class="cls-1"
            d="m942.71,427.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m948.78,427.44c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            class="cls-1"
            d="m954.86,427.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            class="cls-1"
            d="m959.99,427.44c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
          <path
            class="cls-1"
            d="m964.62,427.28v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="D_0_30"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_30")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_30")}
      >
        <g id="D_0_30S">
          <rect
            className="cls-2"
            x="1008.33"
            y="331.89"
            width="67.75"
            height="57.24"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_30T">
          <path
            className="cls-1"
            d="m1022.54,365.23v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1033.83,365.34c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1039.9,365.39c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m1045.98,365.34c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1051.11,365.39c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m1059.69,365.39c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="D_0_29"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_29")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_29")}
      >
        <g id="D_0_29S">
          <rect
            className="cls-2"
            x="917.53"
            y="331.89"
            width="67.95"
            height="57.24"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_29T">
          <path
            className="cls-1"
            d="m932.06,365.23v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m943.35,365.34c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m949.42,365.39c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m955.5,365.34c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m957.62,365.23v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m968.49,355.98c.83,0,1.55.17,2.16.52.6.35,1.07.85,1.39,1.51.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66c-.69.38-1.49.57-2.4.57-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36s.58.1.89.1c.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85-.27.23-.58.4-.93.52-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61.31-.45.72-.8,1.24-1.05.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16-.22.11-.4.26-.53.47s-.19.44-.19.72c0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7c-.12-.2-.3-.36-.54-.48-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="D_0_28"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_28")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_28")}
      >
        <g id="D_0_28S">
          <rect
            className="cls-2"
            x="1008.33"
            y="269.51"
            width="67.75"
            height="57.17"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_28T">
          <path
            className="cls-1"
            d="m1022.68,301.86v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1033.97,301.97c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1040.03,302.02c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m1046.12,301.97c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1048.23,301.86v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1059.67,302.02c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98c-.32-.42-.48-.91-.48-1.48s.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9s1.24-.31,2-.31,1.45.1,2.02.31,1.02.5,1.33.9.47.87.47,1.44-.16,1.06-.48,1.48c-.32.42-.76.75-1.34.98-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38.29-.39.7-.68,1.23-.9.53-.21,1.13-.32,1.81-.32s1.31.11,1.83.32c.52.21.93.51,1.23.9.3.39.45.85.45,1.38s-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.84-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3-.39.48-.39.84.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="D_0_27"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_27")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_27")}
      >
        <g id="D_0_27S">
          <path
            className="cls-2"
            d="m978.99,248.28c3.57,0,6.5,2.93,6.5,6.5v64.88c0,3.58-2.93,6.5-6.5,6.5h-55.1c-3.58,0-6.5-2.93-6.5-6.5v-43.35c0-3.57,2.93-6.5,6.5-6.5h17.24c3.58,0,6.5-2.92,6.5-6.5v-8.53c0-3.57,2.92-6.5,6.5-6.5h24.86Z"
          />
        </g>
        <g id="D_0_27T">
          <path
            className="cls-1"
            d="m932.17,301.86v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m943.46,301.97c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m949.53,302.02c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m955.61,301.97c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m957.73,301.86v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m966.87,301.86l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
        </g>
      </g>
      <g
        id="D_0_26"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_26")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_26")}
      >
        <g id="D_0_26S">
          <rect
            className="cls-2"
            x="1008.33"
            y="207.62"
            width="67.75"
            height="56.69"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_26T">
          <path
            className="cls-1"
            d="m1020.88,240.02v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1032.16,240.12c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1038.23,240.17c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m1044.31,240.12c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1046.43,240.02v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1058.17,240.17c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="D_0_25"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_25")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_25")}
      >
        <g id="D_0_25S">
          <rect
            className="cls-2"
            x="981.15"
            y="119.54"
            width="51.47"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_0_25T">
          <path
            className="cls-1"
            d="m987.26,156.81v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m998.55,156.92c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1004.62,156.97c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m1010.7,156.92c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1012.81,156.81v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m1023.59,156.97c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_0_24"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_0_24")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_0_24")}
      >
        <g id="D_0_24S">
          <path
            className="cls-2"
            d="m975.94,179.99c0,3.58-2.92,6.5-6.5,6.5h-43.78c-3.58,0-6.48-2.92-6.46-6.5l.29-53.95c.02-3.57,2.96-6.5,6.54-6.5h43.41c3.58,0,6.5,2.92,6.5,6.5v53.95Z"
          />
        </g>
        <g id="D_0_24T">
          <path
            className="cls-1"
            d="m927.34,156.81v-9.1h4.11c.98,0,1.85.19,2.59.57.75.38,1.33.9,1.76,1.58s.63,1.48.63,2.41-.21,1.72-.63,2.4c-.42.68-1.01,1.21-1.76,1.59s-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98s.37-.92.37-1.5-.12-1.08-.37-1.5c-.25-.42-.59-.75-1.03-.98-.44-.23-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m938.63,156.92c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m944.7,156.97c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m950.78,156.92c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m952.9,156.81v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m960.49,154.9v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="C_0_23"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_23")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_23")}
      >
        <g id="C_0_23S">
          <rect
            className="cls-2"
            x="857.79"
            y="119.54"
            width="53.92"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_23T">
          <path
            className="cls-1"
            d="m870.08,156.68c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m875.83,156.63c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m881.9,156.68c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m887.98,156.63c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m890.1,156.52v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m900.73,156.68c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_0_22"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_22")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_22")}
      >
        <g id="C_0_22S">
          <rect
            className="cls-2"
            x="795.32"
            y="119.54"
            width="57.07"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_22T">
          <path
            className="cls-1"
            d="m809.2,156.68c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m814.96,156.63c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m821.03,156.68c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m827.11,156.63c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m829.23,156.52v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m836.84,156.52v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="C_0_21"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_21")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_21")}
      >
        <g id="C_0_21S">
          <rect
            className="cls-2"
            x="795.32"
            y="208.94"
            width="57.07"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_21T">
          <path
            className="cls-1"
            d="m809.89,247.5c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m815.65,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m821.72,247.5c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m827.8,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m829.91,247.35v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m839.01,247.35v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="C_0_20"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_20")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_20")}
      >
        <g id="C_0_20S">
          <rect
            className="cls-2"
            x="733.05"
            y="208.94"
            width="57.07"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_20T">
          <path
            className="cls-1"
            d="m747.56,247.5c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m753.32,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m759.39,247.5c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m765.47,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m767.59,247.35v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m779.16,247.5c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="C_0_19"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_19")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_19")}
      >
        <g id="C_0_19S">
          <rect
            className="cls-2"
            x="733.05"
            y="119.54"
            width="57.07"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_19T">
          <path
            className="cls-1"
            d="m747.89,156.68c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m753.65,156.63c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m759.72,156.68c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m765.8,156.63c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m769.39,156.52v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m776.24,147.27c.83,0,1.55.17,2.16.52.6.35,1.07.85,1.39,1.51.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66c-.69.38-1.49.57-2.4.57-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36s.58.1.89.1c.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85-.27.23-.58.4-.93.52-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61.31-.45.72-.8,1.24-1.05.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16-.22.11-.4.26-.53.47s-.19.44-.19.72c0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7c-.12-.2-.3-.36-.54-.48-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="C_0_18"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_18")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_18")}
      >
        <g id="C_0_18S">
          <rect
            className="cls-2"
            x="670.85"
            y="208.94"
            width="57.2"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_18T">
          <path
            className="cls-1"
            d="m686.65,247.5c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m692.41,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m698.48,247.5c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m704.56,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m708.15,247.35v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m715.56,247.5c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98c-.32-.42-.48-.91-.48-1.48s.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9s1.24-.31,2-.31,1.45.1,2.02.31,1.02.5,1.33.9.47.87.47,1.44-.16,1.06-.48,1.48c-.32.42-.76.75-1.34.98-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38.29-.39.7-.68,1.23-.9.53-.21,1.13-.32,1.81-.32s1.31.11,1.83.32c.52.21.93.51,1.23.9.3.39.45.85.45,1.38s-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.84-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3-.39.48-.39.84.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="C_0_17"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_17")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_17")}
      >
        <g id="C_0_17S">
          <rect
            className="cls-2"
            x="670.85"
            y="119.54"
            width="56.99"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_17T">
          <path
            className="cls-1"
            d="m685.77,156.68c-.7,0-1.34-.11-1.94-.34-.6-.23-1.12-.56-1.55-.98-.44-.42-.78-.92-1.03-1.5s-.37-1.2-.37-1.89.12-1.31.37-1.89.59-1.07,1.03-1.49.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21s-.66.34-.92.6c-.26.26-.46.57-.6.92s-.21.75-.21,1.18.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6s.74.21,1.16.21c.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m691.53,156.63c-.34,0-.64-.12-.88-.36s-.37-.54-.37-.92.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m697.6,156.68c-.74,0-1.4-.18-1.99-.55-.59-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.07,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m703.68,156.63c-.34,0-.64-.12-.88-.36s-.37-.54-.37-.92.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m707.27,156.52v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m712.38,156.52l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
        </g>
      </g>
      <g
        id="C_0_16"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_16")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_16")}
      >
        <g id="C_0_16S">
          <rect
            className="cls-2"
            x="608.92"
            y="208.94"
            width="56.73"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_16T">
          <path
            className="cls-1"
            d="m623.75,247.5c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m629.51,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m635.58,247.5c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m641.66,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m645.25,247.35v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m652.96,247.5c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="C_0_15"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_15")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_15")}
      >
        <g id="C_0_15S">
          <rect
            className="cls-2"
            x="608.92"
            y="119.54"
            width="56.73"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_15T">
          <path
            className="cls-1"
            d="m623.87,156.68c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m629.63,156.63c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m635.7,156.68c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m641.78,156.63c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m645.37,156.52v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m652.11,156.68c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_0_14"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_14")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_14")}
      >
        <g id="C_0_14S">
          <rect
            className="cls-2"
            x="547.18"
            y="208.94"
            width="56.63"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_14T">
          <path
            className="cls-1"
            d="m561.91,247.5c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m567.67,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m573.74,247.5c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m579.82,247.45c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m583.41,247.35v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m586.97,245.43v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="C_0_13"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_0_13")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_0_13")}
      >
        <g id="C_0_13S">
          <rect
            className="cls-2"
            x="547.07"
            y="119.54"
            width="56.56"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_0_13T">
          <path
            className="cls-1"
            d="m561.95,156.97c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.89s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.49s.96-.75,1.56-.98c.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8-.38-.18-.79-.27-1.24-.27-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27.38-.18.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21s-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m567.71,156.92c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m573.78,156.97c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m579.86,156.92c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m583.45,156.81v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m590.05,156.97c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="B_0_12"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_0_12")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_0_12")}
      >
        <g id="B_0_12S">
          <rect
            className="cls-2"
            x="468.35"
            y="58.53"
            width="56.61"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_0_12T">
          <path
            className="cls-1"
            d="m478.74,95.5v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m489.23,95.6c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m495.3,95.65c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m501.38,95.6c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m504.97,95.5v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m508.56,95.5v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="B_0_11"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_0_11")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_0_11")}
      >
        <g id="B_0_11S">
          <path
            className="cls-2"
            d="m411.96,214.67c-3.57,0-6.5-2.93-6.5-6.5v-53.95c0-3.57,2.93-6.5,6.5-6.5h43.84c3.58,0,6.5,2.93,6.5,6.5v15.03c0,3.57,2.93,6.5,6.5,6.5h11.05c3.58,0,6.5,2.92,6.5,6.5v25.93c0,3.57-2.92,6.5-6.5,6.5h-67.89Z"
          />
        </g>
        <g id="B_0_11T">
          <path
            className="cls-1"
            d="m418.85,184.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m429.35,185.09c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m435.42,185.15c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m441.5,185.09c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m445.09,184.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m450.15,184.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="B_0_10"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_0_10")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_0_10")}
      >
        <g id="B_0_10S">
          <rect
            className="cls-2"
            x="406.04"
            y="58.53"
            width="56.74"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_0_10T">
          <path
            className="cls-1"
            d="m415.92,95.5v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m426.42,95.6c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m432.49,95.65c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m438.57,95.6c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m442.16,95.5v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m449.7,95.65c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="B_0_09"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_0_09")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_0_09")}
      >
        <g id="B_0_09S">
          <rect
            className="cls-2"
            x="343.84"
            y="147.72"
            width="56.41"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_0_09T">
          <path
            className="cls-1"
            d="m352.56,186.85v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m363.06,186.96c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m369.13,187.01c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m375.21,186.96c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m381.28,187.01c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m389.35,177.6c.83,0,1.55.17,2.16.52.6.35,1.07.85,1.39,1.51.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66c-.69.38-1.49.57-2.4.57-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36s.58.1.89.1c.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85-.27.23-.58.4-.93.52-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61.31-.45.72-.8,1.24-1.05.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16-.22.11-.4.26-.53.47s-.19.44-.19.72c0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7c-.12-.2-.3-.36-.54-.48-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="B_0_08"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_0_08")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_0_08")}
      >
        <g id="B_0_08S">
          <rect
            className="cls-2"
            x="344.84"
            y="58.53"
            width="55.19"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_0_08T">
          <path
            className="cls-1"
            d="m353.5,96.14v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m364,96.24c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m370.07,96.29c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m376.15,96.24c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m382.22,96.29c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m390.86,96.29c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98c-.32-.42-.48-.91-.48-1.48s.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9s1.24-.31,2-.31,1.45.1,2.02.31,1.02.5,1.33.9.47.87.47,1.44-.16,1.06-.48,1.48c-.32.42-.76.75-1.34.98-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38.29-.39.7-.68,1.23-.9.53-.21,1.13-.32,1.81-.32s1.31.11,1.83.32c.52.21.93.51,1.23.9.3.39.45.85.45,1.38s-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.84-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3-.39.48-.39.84.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="B_0_07"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_0_07")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_0_07")}
      >
        <g id="B_0_07S">
          <rect
            className="cls-2"
            x="222.66"
            y="58.53"
            width="52.6"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_0_07T">
          <path
            className="cls-1"
            d="m228.68,96.14v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83s.37.81.37,1.34c0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m239.18,96.24c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m245.25,96.29c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m251.33,96.24c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m257.4,96.29c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m263.74,96.14l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.36l-3.36,7.74h-2.27Z"
          />
        </g>
      </g>
      <g
        id="A_0_06"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_0_06")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_0_06")}
      >
        <g id="A_0_06S">
          <rect
            className="cls-2"
            x="157.9"
            y="58.53"
            width="56.17"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_0_06T">
          <path
            className="cls-1"
            d="m164.65,96.14l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m176.35,96.24c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m182.42,96.29c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m188.5,96.24c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m194.57,96.29c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m203.51,96.29c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36-.28-.07-.58-.1-.89-.1-.78,0-1.4.24-1.86.72-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52s.75-.18,1.18-.18c.58,0,1.11.12,1.58.35.46.23.84.56,1.12.99.28.42.42.92.42,1.49,0,.62-.15,1.15-.46,1.61-.31.46-.71.81-1.23,1.05-.51.25-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.72,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48c-.13.2-.2.44-.2.71s.06.49.19.7c.13.2.31.36.54.48s.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="A_0_05"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_0_05")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_0_05")}
      >
        <g id="A_0_05S">
          <path
            className="cls-2"
            d="m151.47,118.37c0,3.57-2.92,6.5-6.5,6.5h-64.43c-3.57,0-6.02-2.89-5.43-6.41l8.93-53.52c.59-3.53,3.99-6.41,7.57-6.41h53.36c3.58,0,6.5,2.93,6.5,6.5v53.35Z"
          />
        </g>
        <g id="A_0_05T">
          <path
            className="cls-1"
            d="m94.39,96.14l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m106.09,96.24c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m112.16,96.29c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m118.24,96.24c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m124.31,96.29c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m132.28,96.29c-.63,0-1.25-.08-1.87-.25-.62-.17-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49c-.27.46-.68.82-1.23,1.1-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="A_0_04"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_0_04")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_0_04")}
      >
        <g id="A_0_04S">
          <path
            className="cls-2"
            d="m125.28,207.54c0,3.57-2.92,6.5-6.5,6.5h-53.39c-3.57,0-5.96-2.88-5.31-6.39l13.16-70.69c.65-3.51,4.12-6.39,7.69-6.39h37.85c3.58,0,6.5,2.92,6.5,6.5v70.47Z"
          />
        </g>
        <g id="A_0_04T">
          <path
            className="cls-1"
            d="m75.03,176.1l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m86.73,176.2c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m92.8,176.26c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m98.88,176.2c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m104.95,176.26c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m109.74,174.19v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="A_0_03"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_0_03")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_0_03")}
      >
        <g id="A_0_03S">
          <rect
            className="cls-2"
            x="57.46"
            y="219.87"
            width="68.14"
            height="56.33"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_0_03T">
          <path
            className="cls-1"
            d="m70.55,251.84l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m82.25,251.94c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m88.32,251.99c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m94.4,251.94c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m100.47,251.99c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m108.29,251.99c-.63,0-1.25-.08-1.87-.25-.62-.17-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.36l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09s-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="A_0_02"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_0_02")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_0_02")}
      >
        <g id="A_0_02S">
          <rect
            className="cls-2"
            x="56.98"
            y="280.87"
            width="68.48"
            height="56.04"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_0_02T">
          <path
            className="cls-1"
            d="m70.26,312.69l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m81.96,312.79c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m88.03,312.84c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m94.11,312.79c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m100.18,312.84c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m104.99,312.69v-1.36l3.49-3.31c.28-.25.48-.48.61-.68.13-.2.22-.38.26-.55.05-.16.07-.32.07-.45,0-.36-.12-.64-.37-.84-.25-.19-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.24.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="A_0_01"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_0_01")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_0_01")}
      >
        <g id="A_0_01S">
          <rect
            className="cls-2"
            x="57.11"
            y="343.3"
            width="68.48"
            height="56.68"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_0_01T">
          <path
            className="cls-1"
            d="m71.4,376.3l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m83.1,376.41c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m89.17,376.46c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m95.25,376.41c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m101.32,376.46c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55c-.34.7-.79,1.24-1.38,1.61-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3.26-.2.47-.52.62-.95.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95.26.2.57.3.91.3Z"
          />
          <path
            className="cls-1"
            d="m107.61,376.3v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Floor0;
