import "./styles.scss";

import React, { useState, useEffect } from "react";

import ListHeadItem from "./components/ListHeadItem";
import ListItem from "./components/ListItem";

import ListIcon from "assets/icons/list.svg";
import ListArrow from "assets/icons/list-arrow.svg";
// import SwipeIcon from "assets/icons/swipe.svg";
import PointerIcon from "assets/icons/pointer.svg";

import { useApartmentList } from "../../../context/apartments-list";
import scrollToElement from "utils/scrollToElement";

const mainClass = "apts-list";

const ApartmentsList = ({ setContactModalOpen }) => {
  const { searched_apartments, pageNumber, setPageNumber } = useApartmentList();

  const show_only_available = searched_apartments?.filter(
    (item) =>
      item?.nieruchomosci?.statusId === "1" ||
      item?.nieruchomosci?.statusId === "2"
  );

  const [filteredApartments, setFilteredApartments] =
    useState(show_only_available);

  useEffect(() => {
    setFilteredApartments(show_only_available);
  }, [searched_apartments]);

  const sortApartmentsByParam = (sorting_option, param) => {
    const filtered_by_param = show_only_available.sort((a, b) => {
      return a.nieruchomosci[`${param}`] - b.nieruchomosci[`${param}`];
    });

    if (sorting_option === "desc") {
      const filtered_by_param_reversed = filtered_by_param.reverse();
      setFilteredApartments([...filtered_by_param_reversed]);
    } else {
      setFilteredApartments([...filtered_by_param]);
    }
    setPageNumber(1);
  };

  const head_items = [
    {
      label: "Apartament",
      fn_up: null,
      fn_down: null,
    },
    {
      label: "Metraż",
      fn_up: () => sortApartmentsByParam("desc", "area"),
      fn_down: () => sortApartmentsByParam("asc", "area"),
    },
    {
      label: "Liczba pokoi",
      fn_up: () => sortApartmentsByParam("desc", "rooms"),
      fn_down: () => sortApartmentsByParam("asc", "rooms"),
    },
    {
      label: "Piętro",
      fn_up: () => sortApartmentsByParam("desc", "floor"),
      fn_down: () => sortApartmentsByParam("asc", "floor"),
    },
    {
      label: "Status",
      fn_up: () => sortApartmentsByParam("desc", "statusId"),
      fn_down: () => sortApartmentsByParam("asc", "statusId"),
    },
    {
      label: "Cena",
      fn_up: () => sortApartmentsByParam("desc", "price"),
      fn_down: () => sortApartmentsByParam("desc", "price"),
    },
    {
      label: "Pobierz rzut",
      fn_up: null,
      fn_down: null,
    },
  ];

  //Pagination
  const limit = 20;
  const total_pages = Math.ceil(filteredApartments.length / limit);

  const getPageElements = (pageNumber) => {
    const start_index = (pageNumber - 1) * limit;
    const end_index = start_index + limit;
    return filteredApartments.slice(start_index, end_index);
  };

  const pages_to_show = getPageElements(pageNumber);

  const handleClickPrev = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else {
      return null;
    }
  };

  const handleClickNext = () => {
    if (pageNumber < total_pages) {
      setPageNumber(pageNumber + 1);
      if (pageNumber === total_pages - 1) {
        scrollToElement("#aparments-list");
      }
    } else {
      return null;
    }
  };

  const handleClickPageNumber = (index) => {
    setPageNumber(index + 1);
    if (pageNumber === total_pages - 1) {
      scrollToElement("#aparments-list");
    }
  };

  return (
    <section id="aparments-list" className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__wrapper`}>
          <div className={`${mainClass}__heading`}>
            <ListIcon />
            <h3>Lista ofert</h3>
          </div>
          <div className={`${mainClass}__swipe-icon`}>
            <PointerIcon />
          </div>
          <div className={`${mainClass}__table--wrapper`}>
            <div className={`${mainClass}__table`}>
              <div className={`${mainClass}__table-head`}>
                {head_items.map((item) => (
                  <ListHeadItem key={item?.label} {...item} />
                ))}
                <div className={`${mainClass}__table-head-item`}></div>
              </div>
              <div className={`${mainClass}__table-body`}>
                {pages_to_show?.map((item) => (
                  <ListItem
                    key={item.id}
                    item={item}
                    setContactModalOpen={setContactModalOpen}
                  />
                ))}
              </div>
            </div>
            {filteredApartments?.length === 0 && (
              <div className={`${mainClass}__empty-info`}>
                <p>
                  Brak apartamentów spełniających wybrane kryteria. Zmień
                  parametry wyszukiwania lub skontaktuj się z nami - chętnie
                  pomożemy w dokonaniu najlepszego wyboru.
                </p>
              </div>
            )}
            {filteredApartments?.length > 0 && (
              <div className={`${mainClass}__pagination`}>
                <button
                  type="button"
                  className={`${mainClass}__pagination-arrow${
                    pageNumber > 1 ? "" : " blocked"
                  }`}
                  onClick={() => handleClickPrev()}
                >
                  <ListArrow />
                </button>
                <div className={`${mainClass}__pagination-numbers`}>
                  {Array(total_pages)
                    .fill("")
                    .map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        className={pageNumber === index + 1 ? "active" : ""}
                        onClick={() => handleClickPageNumber(index)}
                      >
                        {index + 1}
                      </button>
                    ))}
                </div>
                <button
                  type="button"
                  className={`${mainClass}__pagination-arrow${
                    pageNumber < total_pages ? "" : " blocked"
                  }`}
                  onClick={() => handleClickNext()}
                >
                  <ListArrow />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApartmentsList;
