import React from "react";

const Floor3 = ({
  onApartmentHover,
  onApartmentHoverOut,
  onApartmentClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1086.24"
      height="639.79"
      viewBox="0 0 1086.24 639.79"
    >
      <g
        id="D_3_172"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_172")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_172")}
      >
        <g id="D_3_172S">
          <path
            className="cls-2"
            d="m895,563.29c0-2.75,2.25-5,5-5h58.8c2.75,0,5,2.25,5,5v7.35c0,2.75,2.25,5,5,5h6.34c2.75,0,5,2.25,5,5v1.4c0,2.75,2.25,5,5,5h46.69c2.75,0,5,2.25,5,5v19.16c0,2.75-2.25,5-5,5h-131.84c-2.75,0-5-2.25-5-5v-47.9Z"
          />
        </g>
        <g id="D_3_172T">
          <path
            className="cls-1"
            d="m912.43,592.73v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m923.72,592.83c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m928.84,592.88c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m934.74,592.83c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m938.33,592.73v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m943.45,592.73l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
          <path
            className="cls-1"
            d="m949.93,592.73v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="D_3_171"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_171")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_171")}
      >
        <g id="D_3_171S">
          <path
            className="cls-2"
            d="m895,499.4c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v48.91c0,2.75-2.25,5-5,4.99l-57.5-.07c-2.75,0-5-2.26-5-5.01v-48.83Z"
          />
        </g>
        <g id="D_3_171T">
          <path
            className="cls-1"
            d="m908.28,527.65v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m919.57,527.75c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m924.69,527.81c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m930.59,527.75c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m934.18,527.65v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m939.3,527.65l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
          <path
            className="cls-1"
            d="m947.25,527.65v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="D_3_170"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_170")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_170")}
      >
        <g id="D_3_170S">
          <path
            className="cls-2"
            d="m985.34,500.91c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_3_170T">
          <path
            className="cls-1"
            d="m996.25,528.57v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1007.53,528.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1012.66,528.72c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m1018.56,528.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1022.15,528.57v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1027.26,528.57l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
          <path
            className="cls-1"
            d="m1037.7,528.72c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="D_3_169"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_169")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_169")}
      >
        <g id="D_3_169S">
          <path
            className="cls-2"
            d="m895,438.74c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v45.66c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-45.58Z"
          />
        </g>
        <g id="D_3_169T">
          <path
            className="cls-1"
            d="m906.59,465.37v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m917.88,465.47c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m923,465.52c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m928.9,465.47c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m932.49,465.37v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m940.2,465.52c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m947.56,456.11c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="D_3_168"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_168")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_168")}
      >
        <g id="D_3_168S">
          <path
            className="cls-2"
            d="m985.34,438.74c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_3_168T">
          <path
            className="cls-1"
            d="m996.22,466.06v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1007.51,466.17c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1012.63,466.22c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m1018.54,466.17c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1022.13,466.06v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1029.83,466.22c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m1037.76,466.22c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="D_3_167"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_167")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_167")}
      >
        <g id="D_3_167S">
          <path
            className="cls-2"
            d="m895,375.35c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v48.35c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-48.27Z"
          />
        </g>
        <g id="D_3_167T">
          <path
            className="cls-1"
            d="m906.7,403.32v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m917.98,403.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m923.11,403.47c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m929.01,403.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m932.6,403.32v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m940.31,403.47c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m945.94,403.32l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
          />
        </g>
      </g>
      <g
        id="D_3_166"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_166")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_166")}
      >
        <g id="D_3_166S">
          <path
            className="cls-2"
            d="m985.34,376.6c0-2.75,2.26-5,5.01-5h56.45c2.75,0,5,2.25,4.99,5l-.06,47.04c0,2.75-2.26,5-5.01,5h-56.45c-2.75,0-5-2.25-4.99-5l.06-47.04Z"
          />
        </g>
        <g id="D_3_166T">
          <path
            className="cls-1"
            d="m996.41,404.26v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1007.7,404.36c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1012.82,404.41c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m1018.72,404.36c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1022.31,404.26v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1030.02,404.41c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m1038.25,404.41c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="D_3_165"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_165")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_165")}
      >
        <g id="D_3_165S">
          <path
            className="cls-2"
            d="m895,313.03c0-2.75,2.25-5,5-5h57.5c2.75,0,5,2.25,5,5v47.24c0,2.75-2.25,5-5,4.99l-57.5-.06c-2.75,0-5-2.26-5-5.01v-47.17Z"
          />
        </g>
        <g id="D_3_165T">
          <path
            className="cls-1"
            d="m905.78,341.37v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m917.07,341.48c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m922.19,341.53c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m928.09,341.48c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m931.68,341.37v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m939.39,341.53c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m946.65,341.53c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_3_164"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_164")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_164")}
      >
        <g id="D_3_164S">
          <rect
            className="cls-2"
            x="985.35"
            y="309.33"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_3_164T">
          <path
            className="cls-1"
            d="m996.07,341.99v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1007.36,342.09c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1012.49,342.15c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m1018.39,342.09c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1021.98,341.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1029.69,342.15c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m1033.77,340.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="D_3_163"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_163")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_163")}
      >
        <g id="D_3_163S">
          <path
            className="cls-2"
            d="m923.35,229.42c0-2.75,2.25-5,5-5h29.16c2.75,0,5,2.25,5,5v68.4c0,2.75-2.25,5-5,5h-57.5c-2.75,0-4.99-2.25-4.98-5l.14-40.82c0-2.75-2.23-5-4.98-4.99l-23.76.03c-2.75,0-5-2.24-5-4.99v-56.97c0-2.75,2.25-5,5-5h18.17c2.75,0,5.03,2.25,5.08,5l.77,50.88c.04,2.75,2.33,5,5.08,5h22.84c2.75,0,5-2.25,5-5v-11.53Z"
          />
        </g>
        <g id="D_3_163T">
          <path
            className="cls-1"
            d="m905.8,278v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m917.09,278.11c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m922.21,278.16c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m928.11,278.11c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m931.7,278v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m939.41,278.16c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m946.53,278.16c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="D_3_162"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_162")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_162")}
      >
        <g id="D_3_162S">
          <rect
            className="cls-2"
            x="985.35"
            y="246.88"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_3_162T">
          <path
            className="cls-1"
            d="m996.71,279.54v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1008,279.64c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1013.12,279.7c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m1019.03,279.64c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1022.62,279.54v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1030.32,279.7c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m1034.43,279.54v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="D_3_161"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_161")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_161")}
      >
        <g id="D_3_161S">
          <rect
            className="cls-2"
            x="985.35"
            y="184.02"
            width="66.45"
            height="57.73"
            rx="5"
            ry="5"
          />
        </g>
        <g id="D_3_161T">
          <path
            className="cls-1"
            d="m997.99,216.68v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m1009.28,216.78c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1014.4,216.83c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m1020.3,216.78c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m1023.89,216.68v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m1031.6,216.83c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m1037.18,216.68v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="D_3_160"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_160")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_160")}
      >
        <g id="D_3_160S">
          <rect
            className="cls-2"
            x="958.31"
            y="95.68"
            width="51.47"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="D_3_160T">
          <path
            className="cls-1"
            d="m960.4,134.76v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m971.69,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m976.81,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m982.71,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m986.3,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m994.01,134.92c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
          <path
            className="cls-1"
            d="m1002.07,134.92c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="D_3_159"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("D_3_159")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("D_3_159")}
      >
        <g id="D_3_159S">
          <path
            className="cls-2"
            d="m952.96,156.13c0,3.57-2.92,6.5-6.5,6.5h-43.78c-3.58,0-6.48-2.92-6.46-6.5l.29-53.95c.02-3.57,2.96-6.5,6.54-6.5h43.41c3.58,0,6.5,2.93,6.5,6.5v53.95Z"
          />
        </g>
        <g id="D_3_159T">
          <path
            className="cls-1"
            d="m902.7,132.95v-9.1h4.11c.98,0,1.85.19,2.59.57s1.33.9,1.76,1.58c.42.68.63,1.48.63,2.41s-.21,1.72-.63,2.4-1.01,1.21-1.76,1.59-1.61.57-2.59.57h-4.11Zm2.09-1.73h1.91c.6,0,1.12-.11,1.57-.34.44-.23.79-.56,1.03-.98.25-.42.37-.92.37-1.49s-.12-1.08-.37-1.5-.59-.75-1.03-.98-.97-.34-1.57-.34h-1.91v5.64Z"
          />
          <path
            className="cls-1"
            d="m913.98,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m919.11,133.11c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m925.01,133.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m928.6,132.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m935.34,133.11c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m943.13,123.7c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="C_3_158"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_158")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_158")}
      >
        <g id="C_3_158S">
          <rect
            className="cls-2"
            x="833.51"
            y="95.68"
            width="53.92"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="C_3_158T">
          <path
            className="cls-1"
            d="m844.31,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m850.07,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m855.2,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m861.1,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m864.69,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m871.43,134.92c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m879.78,134.92c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="C_3_157"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_157")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_157")}
      >
        <g id="C_3_157S">
          <rect
            className="cls-2"
            x="771.04"
            y="95.68"
            width="57.07"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="C_3_157T">
          <g>
            <path
              className="cls-1"
              d="m782.13,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m787.89,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m793.02,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m798.92,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m802.51,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m809.25,134.92c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m815.31,134.76l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_3_156"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_156")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_156")}
      >
        <g id="C_3_156S">
          <rect
            className="cls-2"
            x="772.34"
            y="185.08"
            width="83.87"
            height="66.97"
            rx="5"
            ry="5"
          />
        </g>
        <g id="C_3_156T">
          <path
            className="cls-1"
            d="m797.31,222.51c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m803.07,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m808.19,222.51c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m814.1,222.46c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m817.69,222.36v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m824.43,222.51c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m833.08,222.51c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="C_3_155"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_155")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_155")}
      >
        <g id="C_3_155S">
          <rect
            className="cls-2"
            x="708.76"
            y="185.08"
            width="57.07"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="C_3_155T">
          <path
            className="cls-1"
            d="m720.52,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m726.28,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m731.4,223.64c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m737.3,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m740.89,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m747.63,223.64c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m755.32,223.64c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_3_154"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_154")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_154")}
      >
        <g id="C_3_154S">
          <rect
            className="cls-2"
            x="708.76"
            y="95.68"
            width="57.07"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="C_3_154T">
          <path
            className="cls-1"
            d="m721.21,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m726.97,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m732.1,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m738,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m741.59,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m748.33,134.92c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m752.84,132.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="C_3_153"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_153")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_153")}
      >
        <g id="C_3_153S">
          <rect
            className="cls-2"
            x="646.57"
            y="185.08"
            width="57.2"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="C_3_153T">
          <path
            className="cls-1"
            d="m659.53,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m665.29,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m670.42,223.64c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m676.32,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m679.91,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m686.65,223.64c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m694.19,223.64c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
        </g>
      </g>
      <g
        id="C_3_152"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_152")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_152")}
      >
        <g id="C_3_152S">
          <rect
            className="cls-2"
            x="646.57"
            y="95.68"
            width="56.99"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="C_3_152T">
          <g>
            <path
              className="cls-1"
              d="m659.55,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m665.3,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m670.43,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m676.33,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m679.92,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m686.66,134.92c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m691.19,134.76v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
          </g>
        </g>
      </g>
      <g
        id="C_3_151"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_151")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_151")}
      >
        <g id="C_3_151S">
          <rect
            className="cls-2"
            x="584.64"
            y="185.08"
            width="56.73"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="C_3_151T">
          <path
            className="cls-1"
            d="m597.77,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m603.53,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m608.66,223.64c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m614.56,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m618.15,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m624.89,223.64c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m630.89,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="C_3_150"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_150")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_150")}
      >
        <g id="C_3_150S">
          <rect
            className="cls-2"
            x="584.64"
            y="95.68"
            width="56.73"
            height="66.37"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_3_150T">
          <path
            className="cls-1"
            d="m595.92,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m601.68,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m606.81,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m612.71,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m616.3,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m623.04,134.92c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m631.52,134.92c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
          />
        </g>
      </g>
      <g
        id="C_3_149"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_149")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_149")}
      >
        <g id="C_3_149S">
          <rect
            className="cls-2"
            x="522.9"
            y="185.08"
            width="56.63"
            height="66.97"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="C_3_149T">
          <path
            className="cls-1"
            d="m534.08,223.64c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
          />
          <path
            className="cls-1"
            d="m539.84,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m544.96,223.64c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m550.87,223.59c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m554.46,223.49v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m558.02,221.57v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m570.2,214.23c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="C_3_148"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("C_3_148")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("C_3_148")}
      >
        <g id="C_3_148S">
          <rect
            className="cls-2"
            x="522.78"
            y="95.68"
            width="56.56"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="C_3_148T">
          <g>
            <path
              className="cls-1"
              d="m533.93,134.92c-.7,0-1.34-.11-1.94-.34s-1.12-.56-1.56-.98c-.44-.42-.78-.92-1.03-1.5-.25-.57-.37-1.2-.37-1.88s.12-1.31.37-1.89c.25-.57.59-1.07,1.03-1.5.44-.42.96-.75,1.56-.98.59-.23,1.24-.34,1.95-.34.78,0,1.49.14,2.12.41s1.16.67,1.59,1.2l-1.34,1.25c-.31-.36-.65-.62-1.03-.8s-.79-.27-1.24-.27c-.42,0-.81.07-1.16.21-.35.14-.66.34-.92.6-.26.26-.46.57-.6.92-.14.36-.21.75-.21,1.18s.07.83.21,1.18c.14.36.34.66.6.92.26.26.56.46.92.6.35.14.74.21,1.16.21.45,0,.86-.09,1.24-.27s.72-.45,1.03-.81l1.34,1.25c-.43.53-.96.93-1.59,1.21-.63.28-1.35.42-2.14.42Z"
            />
            <path
              className="cls-1"
              d="m539.69,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m544.82,134.92c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m550.72,134.87c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m554.31,134.76v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m557.87,132.85v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m570.62,134.92c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_3_147"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_147")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_147")}
      >
        <g id="B_3_147S">
          <rect
            className="cls-2"
            x="444.07"
            y="34.67"
            width="56.61"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="B_3_147T">
          <g>
            <path
              className="cls-1"
              d="m449.28,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m459.78,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m464.91,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m470.81,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m474.4,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m477.96,70.36v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m488.41,72.28l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_3_146"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_146")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_146")}
      >
        <g id="B_3_146S">
          <path
            className="cls-2"
            d="m387.67,190.81c-3.57,0-6.5-2.93-6.5-6.5v-53.95c0-3.57,2.93-6.5,6.5-6.5h43.84c3.58,0,6.5,2.93,6.5,6.5v15.03c0,3.57,2.92,6.5,6.5,6.5h11.05c3.58,0,6.5,2.93,6.5,6.5v25.93c0,3.57-2.92,6.5-6.5,6.5h-67.89Z"
          />
        </g>

        <g id="B_3_146T">
          <path
            className="cls-1"
            d="m389.49,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m399.99,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m405.12,163.15c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m411.02,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m414.61,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m418.17,161.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m431.22,163.15c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
          />
        </g>
      </g>
      <g
        id="B_3_145"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_145")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_145")}
      >
        <g id="B_3_145S">
          <rect
            className="cls-2"
            x="381.76"
            y="34.67"
            width="56.74"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="B_3_145T">
          <g>
            <path
              className="cls-1"
              d="m387.27,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m397.77,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m402.89,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m408.79,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m412.38,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m415.95,70.36v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m428.02,72.43c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_3_144"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_144")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_144")}
      >
        <g id="B_3_144S">
          <rect
            className="cls-2"
            x="319.55"
            y="123.86"
            width="56.41"
            height="66.95"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_3_144T">
          <path
            className="cls-1"
            d="m325.91,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m336.41,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m341.54,163.15c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m347.44,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m351.03,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m354.59,161.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m363.49,161.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="B_3_143"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_143")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_143")}
      >
        <g id="B_3_143S">
          <rect
            className="cls-2"
            x="320.56"
            y="34.67"
            width="55.19"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="B_3_143T">
          <g>
            <path
              className="cls-1"
              d="m327.63,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m338.13,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m343.25,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m349.15,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m352.74,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m356.31,70.36v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m368.24,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_3_142"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_142")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_142")}
      >
        <g id="B_3_142S">
          <path
            className="cls-2"
            d="m261.41,190.09c-2.75-.04-5-2.32-5-5.07v-56.16c0-2.75,2.25-5,5-5h47.56c2.75,0,5,2.25,5,5v56.95c0,2.75-2.25,4.97-5,4.93l-47.56-.65Z"
          />
        </g>
        <g id="B_3_142T">
          <path
            className="cls-1"
            d="m263.39,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m273.89,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m279.01,163.15c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m284.91,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m288.5,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m292.07,161.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
          <path
            className="cls-1"
            d="m300.99,162.99v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
        </g>
      </g>
      <g
        id="B_3_141"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_141")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_141")}
      >
        <g id="B_3_141S">
          <path
            className="cls-2"
            d="m313.97,94.51c0,3.58-3.35,6.5-7.43,6.5h-42.69c-4.09,0-7.43-2.92-7.43-6.5v-53.35c0-3.57,3.35-6.5,7.43-6.5h42.69c4.09,0,7.43,2.93,7.43,6.5v53.35Z"
          />
        </g>
        <g id="B_3_141T">
          <g>
            <path
              className="cls-1"
              d="m264.22,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m274.72,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m279.84,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m285.74,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m289.33,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m292.9,70.36v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m303.29,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_3_140"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_140")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_140")}
      >
        <g id="B_3_140S">
          <path
            className="cls-2"
            d="m200.86,190.09c-2.75-.04-6.26-.07-7.79-.07s-2.8,2.25-2.8,5v26.53c0,2.75-2.25,5-5,5h-27.4c-2.75,0-5-2.25-5-5v-54.89c0-2.75,2.25-5,5-5h27.4c2.75,0,4.98,2.25,4.96,5l-.11,12.12c-.02,2.75,1.26,5,2.85,5s2.89-2.25,2.89-5v-49.92c0-2.75,2.25-5,5-5h44.96c2.75,0,5,2.25,5,5v56.95c0,2.75-2.25,4.97-5,4.93l-44.96-.65Z"
          />
        </g>
        <g id="B_3_140T">
          <g>
            <path
              className="cls-1"
              d="m200.96,162.99v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
            />
            <path
              className="cls-1"
              d="m211.46,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m216.59,163.15c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m222.49,163.1c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m226.08,162.99v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m229.64,161.08v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
            />
            <path
              className="cls-1"
              d="m242.52,163.15c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="B_3_139"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("B_3_139")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("B_3_139")}
      >
        <g id="B_3_139S">
          <rect
            className="cls-2"
            x="198.37"
            y="34.67"
            width="52.6"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="B_3_139T">
          <path
            className="cls-1"
            d="m202.88,72.28v-9.1h4.42c1.14,0,1.99.22,2.56.65.57.43.86,1.01.86,1.72,0,.48-.12.89-.35,1.24-.23.35-.55.62-.96.81-.4.19-.87.29-1.39.29l.25-.53c.57,0,1.07.09,1.51.28.44.19.78.46,1.03.83.25.36.37.81.37,1.34,0,.78-.31,1.39-.92,1.83-.61.44-1.51.66-2.7.66h-4.67Zm2.08-1.59h2.44c.54,0,.95-.09,1.23-.27.28-.18.42-.46.42-.85s-.14-.67-.42-.85c-.28-.19-.69-.28-1.23-.28h-2.6v-1.53h2.23c.51,0,.9-.09,1.17-.27.27-.18.41-.45.41-.81s-.14-.62-.41-.8c-.27-.18-.66-.27-1.17-.27h-2.08v5.93Z"
          />
          <path
            className="cls-1"
            d="m213.38,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m218.51,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m224.41,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m228,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m234.6,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m242.49,63.02c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="A_3_138"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_138")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_138")}
      >
        <g id="A_3_138S">
          <rect
            className="cls-2"
            x="133.61"
            y="34.67"
            width="56.17"
            height="66.35"
            rx="6.5"
            ry="6.5"
          />
        </g>

        <g id="A_3_138T">
          <path
            className="cls-1"
            d="m138.82,72.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m150.52,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m155.64,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m161.54,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m165.13,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m171.73,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m180.19,72.43c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
      <g
        id="A_3_137"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_137")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_137")}
      >
        <g id="A_3_137S">
          <path
            className="cls-2"
            d="m127.19,94.51c0,3.58-2.93,6.5-6.5,6.5H56.26c-3.57,0-6.02-2.89-5.43-6.41l8.93-53.52c.59-3.53,3.99-6.41,7.57-6.41h53.36c3.57,0,6.5,2.93,6.5,6.5v53.35Z"
          />
        </g>

        <g id="A_3_137T">
          <g>
            <path
              className="cls-1"
              d="m68.54,72.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m80.24,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m85.37,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m91.27,72.38c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m94.86,72.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m101.46,72.43c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m107.62,72.28l3.64-8.29.54.91h-4.87l.93-.99v2.47h-1.86v-3.2h7.24v1.37l-3.36,7.73h-2.27Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_3_136"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_136")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_136")}
      >
        <g id="A_3_136S">
          <path
            className="cls-2"
            d="m101,183.68c0,3.58-2.93,6.5-6.5,6.5h-53.39c-3.58,0-5.96-2.88-5.31-6.39l13.16-70.69c.65-3.51,4.12-6.39,7.69-6.39h37.85c3.57,0,6.5,2.93,6.5,6.5v70.47Z"
          />
        </g>
        <g id="A_3_136T">
          <g>
            <path
              className="cls-1"
              d="m46.74,157.74l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m58.43,157.85c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m63.56,157.9c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m69.46,157.85c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m73.05,157.74v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m79.65,157.9c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m88.4,157.9c-.83,0-1.54-.17-2.15-.52-.61-.35-1.07-.85-1.4-1.52-.33-.67-.49-1.49-.49-2.47,0-1.05.2-1.94.59-2.67s.93-1.28,1.63-1.66c.69-.38,1.49-.57,2.4-.57.48,0,.94.05,1.38.16.44.1.82.26,1.14.47l-.77,1.55c-.25-.17-.52-.29-.8-.36s-.58-.1-.89-.1c-.78,0-1.4.24-1.86.71-.46.48-.68,1.18-.68,2.12,0,.16,0,.33,0,.52,0,.19.03.38.07.57l-.58-.55c.16-.34.37-.62.63-.85.26-.23.57-.4.92-.52.36-.12.75-.18,1.18-.18.58,0,1.11.12,1.58.35s.84.56,1.12.99c.28.42.42.92.42,1.5,0,.62-.15,1.15-.46,1.61s-.71.81-1.23,1.05-1.09.37-1.74.37Zm-.12-1.57c.29,0,.55-.05.78-.16.23-.11.41-.27.54-.47.13-.21.19-.45.19-.71,0-.42-.14-.75-.42-.99-.28-.25-.65-.37-1.12-.37-.31,0-.58.06-.81.18s-.42.28-.55.48-.2.44-.2.71.06.49.19.7c.13.2.31.36.54.48.23.12.52.18.85.18Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_3_135"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_135")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_135")}
      >
        <g id="A_3_135S">
          <rect
            className="cls-2"
            x="33.17"
            y="196.01"
            width="68.14"
            height="56.33"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_3_135T">
          <g>
            <path
              className="cls-1"
              d="m44.84,227.98l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m56.54,228.08c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m61.67,228.13c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m67.57,228.08c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m71.16,227.98v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m77.76,228.13c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m85.54,228.13c-.63,0-1.25-.08-1.87-.25s-1.14-.41-1.58-.72l.83-1.61c.34.25.74.45,1.19.6.45.15.91.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.5.45-.87,0-.23-.06-.44-.18-.62-.12-.18-.33-.32-.64-.42-.31-.1-.73-.14-1.29-.14h-2.31l.46-5.12h5.51v1.69h-4.76l1.08-.96-.32,3.65-1.08-.96h1.91c.9,0,1.62.12,2.16.37s.95.58,1.19,1.01c.25.42.38.91.38,1.44s-.13,1.03-.4,1.49-.68.82-1.23,1.1c-.56.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_3_134"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_134")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_134")}
      >
        <g id="A_3_134S">
          <path
            className="cls-2"
            d="m128.32,314.89c-2.75,0-5-2.25-5.01-5l-.1-73.14c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,73.14c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_3_134T">
          <path
            className="cls-1"
            d="m133.78,277.12l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m145.48,277.22c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m150.61,277.27c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m156.51,277.22c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m160.1,277.12v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m166.7,277.27c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m171.31,275.21v-1.42l4.26-5.77h2.2l-4.17,5.77-1.02-.3h7.12v1.72h-8.38Zm4.87,1.91v-1.91l.06-1.72v-1.7h1.98v5.33h-2.04Z"
          />
        </g>
      </g>
      <g
        id="A_3_133"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_133")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_133")}
      >
        <g id="A_3_133S">
          <path
            className="cls-2"
            d="m101.32,307.95c0,3.98-2.93,7.24-6.5,7.24h-55.07c-3.57,0-6.5-3.26-6.5-7.24l-.42-45.36c0-3.98,2.92-7.24,6.5-7.24h55.48c3.57,0,6.5,3.26,6.5,7.24v45.36Z"
          />
        </g>
        <g id="A_3_133T">
          <g>
            <path
              className="cls-1"
              d="m44.42,288.28l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m56.12,288.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m61.25,288.44c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m67.15,288.39c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m70.74,288.28v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m77.34,288.44c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m84.98,288.44c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_3_132"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_132")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_132")}
      >
        <g id="A_3_132S">
          <path
            className="cls-2"
            d="m128.32,377.43c-2.75,0-5-2.25-5.01-5l-.09-47.05c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,47.05c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_3_132T">
          <g>
            <path
              className="cls-1"
              d="m134.42,352.7l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m146.12,352.8c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m151.25,352.86c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m157.15,352.8c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m160.74,352.7v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m167.34,352.86c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m171.97,352.7v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_3_131"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_131")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_131")}
      >
        <g id="A_3_131S">
          <rect
            className="cls-2"
            x="32.83"
            y="320.82"
            width="68.48"
            height="56.68"
            rx="6.5"
            ry="6.5"
          />
        </g>
        <g id="A_3_131T">
          <path
            className="cls-1"
            d="m45.98,352.95l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m57.68,353.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m62.81,353.11c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m68.71,353.06c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m72.3,352.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m78.9,353.11c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m85,352.95v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
        </g>
      </g>
      <g
        id="A_3_130"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_130")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_130")}
      >
        <g id="A_3_130S">
          <path
            className="cls-2"
            d="m128.32,440.04c-2.75,0-5-2.25-5.01-5l-.09-47.05c0-2.75,2.24-5,4.99-5h57.17c2.75,0,5,2.25,4.99,5l-.09,47.05c0,2.75-2.26,5-5.01,5h-56.95Z"
          />
        </g>
        <g id="A_3_130T">
          <g>
            <path
              className="cls-1"
              d="m133.85,415.31l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
            />
            <path
              className="cls-1"
              d="m145.55,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m150.67,415.47c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m156.57,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
            />
            <path
              className="cls-1"
              d="m160.16,415.31v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
            />
            <path
              className="cls-1"
              d="m166.76,415.47c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
            />
            <path
              className="cls-1"
              d="m175.35,415.47c-.74,0-1.4-.18-1.99-.55-.58-.37-1.05-.9-1.38-1.61-.33-.7-.5-1.55-.5-2.55s.17-1.85.5-2.55c.34-.7.8-1.24,1.38-1.61.59-.37,1.25-.55,1.99-.55s1.41.18,2,.55c.58.37,1.04.9,1.38,1.61.34.7.5,1.55.5,2.55s-.17,1.85-.5,2.55-.79,1.24-1.38,1.61c-.58.37-1.25.55-2,.55Zm0-1.78c.35,0,.66-.1.92-.3s.47-.52.62-.95c.15-.43.23-.99.23-1.68s-.08-1.24-.23-1.68c-.15-.43-.36-.75-.62-.95-.26-.2-.57-.3-.92-.3s-.65.1-.91.3c-.26.2-.47.52-.62.95-.15.43-.23.99-.23,1.68s.08,1.24.23,1.68c.15.43.36.75.62.95s.57.3.91.3Z"
            />
          </g>
        </g>
      </g>
      <g
        id="A_3_129"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_129")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_129")}
      >
        <g id="A_3_129S">
          <path
            className="cls-2"
            d="m37.92,440.04c-2.75,0-5-2.25-5.01-5l-.1-47.39c0-2.75,2.24-5,4.99-5h58.13c2.75,0,5,2.25,4.99,5l-.09,47.39c0,2.75-2.26,5-5.01,5h-57.9Z"
          />
        </g>
        <g id="A_3_129T">
          <path
            className="cls-1"
            d="m44.68,415.31l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m56.38,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m61.5,415.47c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m67.4,415.42c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m70.99,415.31v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m74.58,415.31v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m85.46,406.06c.83,0,1.55.17,2.16.52s1.07.85,1.39,1.51c.33.66.49,1.49.49,2.48,0,1.05-.2,1.94-.59,2.67s-.93,1.28-1.62,1.66-1.49.57-2.4.57c-.47,0-.93-.05-1.37-.16-.44-.1-.82-.26-1.14-.47l.78-1.55c.25.17.52.29.8.36.28.07.58.1.89.1.78,0,1.39-.24,1.85-.71.46-.48.69-1.18.69-2.12,0-.16,0-.33-.01-.52,0-.19-.03-.38-.06-.57l.57.55c-.15.34-.35.62-.62.85s-.58.4-.93.52c-.35.12-.75.18-1.19.18-.58,0-1.1-.12-1.57-.35-.47-.23-.84-.56-1.12-.99-.28-.42-.42-.92-.42-1.5,0-.62.15-1.16.46-1.61s.72-.8,1.24-1.05c.52-.25,1.1-.37,1.72-.37Zm.13,1.57c-.3,0-.56.05-.79.16s-.4.26-.53.47c-.13.2-.19.44-.19.72,0,.42.14.75.42.99.28.25.65.37,1.12.37.3,0,.57-.06.81-.18.24-.12.42-.28.55-.49.13-.21.19-.44.19-.7s-.06-.49-.19-.7-.3-.36-.54-.48c-.23-.12-.52-.18-.85-.18Z"
          />
        </g>
      </g>
      <g
        id="A_3_128"
        className="floor-view__item"
        onMouseEnter={() => onApartmentHover("A_3_128")}
        onMouseLeave={() => onApartmentHoverOut()}
        onClick={() => onApartmentClick("A_3_128")}
      >
        <g id="A_3_128S">
          <path
            className="cls-2"
            d="m37.92,501.86c-2.75,0-5-2.25-5.01-5l-.1-46.65c0-2.75,2.24-5,4.99-5h58.13c2.75,0,5,2.25,4.99,5v6.67c-.02,2.75,1.17,5,2.62,5s2.64-2.25,2.64-5v-24.19c0-2.75,2.25-5,5-5h1.82c2.75,0,5,2.25,5,5v24.19c0,2.75,1.16,5,2.58,5s2.58-2.25,2.58-5v-6.67c0-2.75,2.25-5,5-5h57.12c2.75,0,5,2.25,5,5v46.65c0,2.75-2.25,5-5,5H37.92Z"
          />
        </g>
        <g id="A_3_128T">
          <path
            className="cls-1"
            d="m89.74,483.57l4.03-9.1h2.07l4.04,9.1h-2.2l-3.31-8.03h.83l-3.32,8.03h-2.14Zm2.01-1.95l.55-1.6h4.65l.57,1.6h-5.77Z"
          />
          <path
            className="cls-1"
            d="m101.44,483.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m106.56,483.72c-.63,0-1.25-.08-1.87-.25s-1.15-.41-1.58-.72l.81-1.61c.34.25.74.45,1.2.6.46.15.92.22,1.38.22.53,0,.94-.1,1.24-.31.3-.21.45-.49.45-.86s-.13-.62-.4-.82c-.27-.2-.7-.3-1.29-.3h-.96v-1.39l2.52-2.87.23.75h-4.74v-1.69h6.33v1.37l-2.5,2.87-1.06-.61h.61c1.11,0,1.95.25,2.52.75.57.5.85,1.15.85,1.94,0,.51-.13.99-.4,1.44-.27.45-.68.81-1.23,1.09-.55.28-1.26.42-2.12.42Z"
          />
          <path
            className="cls-1"
            d="m112.46,483.67c-.34,0-.64-.12-.88-.36-.25-.24-.37-.54-.37-.92s.12-.67.37-.9c.25-.23.54-.34.88-.34s.65.11.89.34c.24.23.36.53.36.9s-.12.68-.36.92c-.24.24-.54.36-.89.36Z"
          />
          <path
            className="cls-1"
            d="m116.05,483.57v-8.32l.9.91h-2.71v-1.69h3.9v9.1h-2.09Z"
          />
          <path
            className="cls-1"
            d="m119.64,483.57v-1.37l3.49-3.31c.28-.25.48-.48.61-.68s.22-.38.26-.55c.05-.16.07-.32.07-.46,0-.36-.12-.64-.37-.84-.25-.2-.6-.29-1.08-.29-.38,0-.73.07-1.05.22-.32.15-.6.38-.82.69l-1.52-.99c.34-.52.83-.93,1.45-1.23.62-.3,1.33-.46,2.14-.46.67,0,1.26.11,1.76.33.5.22.9.53,1.18.93.28.4.42.88.42,1.43,0,.29-.04.59-.11.88-.07.29-.22.6-.44.92-.22.32-.54.68-.96,1.08l-2.89,2.74-.4-.77h5.1v1.72h-6.83Z"
          />
          <path
            className="cls-1"
            d="m131.08,483.72c-.76,0-1.42-.11-1.99-.34s-1.01-.55-1.33-.98-.48-.91-.48-1.48.16-1.04.47-1.44c.31-.39.76-.69,1.32-.9.57-.2,1.24-.31,2-.31s1.45.1,2.02.31c.57.2,1.02.5,1.33.9.31.39.47.87.47,1.44s-.16,1.06-.48,1.48-.76.75-1.34.98c-.57.23-1.24.34-2.01.34Zm0-4.37c-.7,0-1.31-.1-1.83-.29-.52-.19-.92-.47-1.21-.84-.29-.37-.43-.81-.43-1.32,0-.54.15-1,.44-1.38s.7-.68,1.23-.9,1.13-.32,1.81-.32,1.31.11,1.83.32c.52.21.93.51,1.23.9s.45.85.45,1.38-.14.95-.43,1.32c-.29.37-.7.65-1.22.84-.52.19-1.14.29-1.85.29Zm0,2.82c.53,0,.94-.12,1.25-.35.31-.23.46-.55.46-.96s-.15-.73-.46-.96c-.31-.23-.72-.34-1.25-.34s-.92.11-1.23.34c-.3.23-.45.55-.45.96s.15.73.45.96c.3.23.71.35,1.23.35Zm0-4.06c.44,0,.79-.1,1.04-.3.25-.2.38-.47.38-.82,0-.36-.13-.65-.39-.85-.26-.2-.61-.3-1.03-.3s-.75.1-1.01.3c-.26.2-.39.48-.39.85s.12.62.38.82c.25.2.59.3,1.02.3Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Floor3;
