import React, { useState } from "react";

import Layout from "components/Layout";
import ContactModal from "components/ContactModal";
import SearchBar from "components/SearchBar";
import ContactSection from "components/ContactSection";
import {
  ChooseFloor,
  SingleFloor,
  ApartmentsList,
  SingleRoom,
} from "../page_components/apartments";

// import scrollToElement from "utils/scrollToElement";

const Apartments = ({ location }) => {
  const { hash, pathname, search } = location;
  const [contact_modal_open, setContactModalOpen] = useState(null);
  // const [init_loading, setInitLoading] = useState(false);

  // useEffect(() => {
  //   setInitLoading(true);
  // }, [hash]);

  // useEffect(() => {
  //   if (!!!hash) return;

  //   if (!!init_loading) {
  //     scrollToElement(`${hash.split("?")[0]}-section`);
  //   } else {
  //     setTimeout(() => {
  //       scrollToElement(`${hash.split("?")[0]}-section`);
  //     }, 100);
  //   }

  //   setInitLoading(true);

  //   // eslint-disable-next-line
  // }, [location]);

  return (
    <Layout
      seo={{
        title: "Forest Międzyzdroje - apartamenty nad morzem na sprzedaż",
        description:
          "Forest Międzyzdroje to inwestycja condo nad morzem oferująca apartamenty inwestycyjne na sprzedaż. Kup i zarabiaj na własnej nieruchomości nad Bałtykiem.",
        keywords:
          "Apartamenty na sprzedaż nad morzem w Międzyzdrojach, Luksusowe apartamenty nad Bałtykiem na sprzedaż, Luksusowe apartamenty inwestycyjne w otulinie lasu nad morzem!, Apartamenty inwestycyjne w Międzyzdrojach, Zainwestuj w luksusowy apartament nad morzem, Zainwestuj w apartament w Międzyzdrojach, Zainwestuj w apartamenty blisko Alei Gwiazd, Zainwestuj we własny apartament nad morzem, Zainwestuj w apartament nad morzem i zarabiaj!, Kup apartament w luksusowym obiekcie nad Morzem Bałtyckim, Kup luksusowy apartament w Międzyzdrojach, Kup apartament nad morzem w otulinie parku narodowego, Kup apartament w Międzyzdrojach i zarabiaj na wynajmie!, Apartamenty condo na sprzedaż w Międzyzdrojach, Zainwestuj w apartament condo nad morzem, zarabiaj i wypoczywaj, Kup apartament condo nad morzem i zarabiaj na wynajmie, Inwestuj w apartamenty w Międzyzdrojach, Unikatowa, luksusowa inwestycja w samym sercu lasu w Międzyzdrojach",
      }}
      location={{ pathname, hash }}
      search={search}
      apartments={[]}
      setContactModalOpen={setContactModalOpen}
    >
      <ChooseFloor />
      <SingleFloor />
      <SingleRoom setContactModalOpen={setContactModalOpen} />
      <SearchBar variant="apartments" pathname={pathname} location={location} />
      <ApartmentsList setContactModalOpen={setContactModalOpen} />
      <ContactSection
        search={search}
        investment={contact_modal_open?.investment}
      />
      {contact_modal_open && (
        <ContactModal
          search={search}
          investment={contact_modal_open?.investment}
          onExit={() => setContactModalOpen(null)}
        />
      )}
    </Layout>
  );
};

export default Apartments;
